import apiCall, { makeUrl } from "../../utils/apiCall";

export function* startCustomerPortalSession({ preselect, planLink }) {
  const customerPortalSessionCall = yield apiCall();

  const result = yield customerPortalSessionCall.post(makeUrl("/create-customer-portal-session"), {
    preselect,
  });

  if (result.data.checkoutSession) {
    window.location = result.data.checkoutSession;
    return;
  }
  if (planLink) {
    window.location = result.data.redirect + planLink;
  } else {
    window.location = result.data.redirect;
  }
}
