import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import { getFeed, getFeedRolls, scheduleFeedRegeneration } from "./FeedSaga";

export function* updateDynaamicContentEnabled({ feedId, enabled }) {
  const key = "use-dynamic-content";
  yield put({ type: "SET_LOADING", key, loading: true });
  const patchFeedCall = yield apiCall();
  yield patchFeedCall.patch(makeUrl("/feed/use-dynamic-content"), {
    feedId,
    enabled,
  });
  yield getFeed({ feedId, silent: true });
  yield put({ type: "SET_LOADING", key, loading: false });
}

export function* saveDynamicContent({
  feedId,
  slot1,
  slot2,
  slot3,
  s1Enabled,
  s2Enabled,
  s3Enabled,
  s1StartsAt,
  s2StartsAt,
  s3StartsAt,
  s1EndsAt,
  s2EndsAt,
  s3EndsAt,
  s1tags,
  s2tags,
  s3tags,
}) {
  yield put({
    type: "SET_LOADING",
    key: "saving-dynamic-content",
    loading: true,
  });
  const postFeedSlotRuleCall = yield apiCall();
  yield postFeedSlotRuleCall.post(makeUrl("/feed-slot-rule"), {
    feedId,
    slot: slot1,
    enabled: s1Enabled,
    startsAt: s1StartsAt,
    endsAt: s1EndsAt,
    listenerTagsExpression: s1tags,
  });
  yield postFeedSlotRuleCall.post(makeUrl("/feed-slot-rule"), {
    feedId,
    slot: slot2,
    enabled: s2Enabled,
    startsAt: s2StartsAt,
    endsAt: s2EndsAt,
    listenerTagsExpression: s2tags,
  });
  yield postFeedSlotRuleCall.post(makeUrl("/feed-slot-rule"), {
    feedId,
    slot: slot3,
    enabled: s3Enabled,
    startsAt: s3StartsAt,
    endsAt: s3EndsAt,
    listenerTagsExpression: s3tags,
  });
  yield scheduleFeedRegeneration({ feedId });
  yield getFeedRolls({ feedId, silent: true });
  yield put({
    type: "SET_LOADING",
    key: "saving-dynamic-content",
    loading: false,
  });
}
