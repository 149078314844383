import { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  /**
   * IMPORTANT! do not install `history` package manually.
   * @see https://reactrouter.com/docs/en/v6/routers/history-router
   */
  unstable_HistoryRouter as BaseHistoryRouter,
  HistoryRouterProps,
} from "react-router-dom";

import history from "./history";

const HistoryRouter: FunctionComponent<Omit<HistoryRouterProps, "history">> = ({
  basename,
  children,
}) => {
  const dispatch = useDispatch();

  // Scroll to top on navigation
  //   useEffect(() => {
  //     const unlisten = history.listen(() => window.scrollTo(0, 0));
  //     dispatch({ type: "CLEAR_SAVED_INDICATORS" }); // TODO: does not seem right???

  //     return unlisten;
  //   }, [dispatch]);

  return (
    <BaseHistoryRouter basename={basename} history={history}>
      {children}
    </BaseHistoryRouter>
  );
};

export default HistoryRouter;
