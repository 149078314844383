import { auth } from "@helloaudio/auth";

import GuardFunction from "../GuardFunction";

/**
 * Requires users to be authenticated.
 * But instead of redirecting users to login page, it redirects them to signup page.
 * Use in case you know user wants to signup. (e.g. User clicked on signup button), otherwise use `loginGuard`.
 *
 * @returns boolean
 */
const signupGuard: GuardFunction = async () => {
  const isAuthenticated = await auth.isAuthenticated();
  if (!isAuthenticated) {
    await auth.signupWithRedirect();
    return false;
  }

  return true;
};

export default signupGuard;
