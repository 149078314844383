import toast from "react-hot-toast";

const initialState = {
  audioInboxIntegrations: [],
  audioInboxRecordings: {},
  audioInboxErrors: {},
  audioInboxLoadMore: {},
  audioInboxRefresh: 0,
  externalEpisodes: [],
  loading: {},
  saved: {},
  actionInstances: [],
  myActions: {},
  myAction: {},
  duplicateAction: false,
  allUsers: [],
  allStatistics: {
    totalDownloads: 0,
    totalFeeds: 0,
    downloadsDataSeries: [],
    feedsDataSeries: [],
  },
  auth0User: {},
  categories: [],
  changePlanModal: false,
  feed: {},
  feedEpisode: {},
  feedEpisodes: [],
  integrations: {
    thinkific: {},
    api_key: "",
  },
  invoices: [],
  listeners: {},
  localUser: {},
  myFeeds: [],
  nextInvoice: {},
  promoCodeValid: null,
  singleEpisode: null,
  rollUploads: {},
  statistics: {
    total: 0,
    dataSeries: [],
    perEpisode: [],
  },
  subscription: {},
  temporaryImage: "",
  thinkificCourses: [],
  feedImport: [],
  feedRolls: {},
  feedSlotRules: {
    slot1: {},
    slot2: {},
    slot3: {},
  },
  mySuperlisteners: [],
  mySuperlistenersTotal: 0,
  superlistener: {},
  tagSuggestions: [],
  features: {
    sms: false,
  },
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case "CLEAR_SAVED_INDICATORS":
      return {
        ...state,
        saved: {},
      };
    case "SET_SAVED":
      return {
        ...state,
        saved: { [action.key]: true },
      };
    case "SET_ERROR":
      // TODO: This does not seem to be a good place for this, move...
      toast.error(action.error, { duration: 8000 });
      return state;
    case "SET_SUCCESS":
      // TODO: This does not seem to be a good place for this, move...
      toast.success(action.success, { duration: 8000 });
      return state;
    case "CLEAR_LOADING":
      return {
        ...state,
        loading: {},
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: { ...state.loading, [action.key]: action.loading },
      };
    case "SAVE_AUTH0_USER":
      return {
        ...state,
        auth0User: action.user,
      };
    case "SAVE_LOCAL_USER":
      return {
        ...state,
        localUser: action.user,
      };
    case "SAVE_AFFILIATE_CODE":
      return {
        ...state,
        localUser: { ...state.localUser, affiliate_link: action.affiliateCode },
      };
    case "SAVE_SUBSCRIPTION":
      return {
        ...state,
        subscription: action.subscription,
      };
    case "SAVE_PROMO_CODE_VALID":
      return {
        ...state,
        promoCodeValid: action.valid,
      };
    case "SAVE_FEED":
      return {
        ...state,
        feed: action.feed,
      };
    case "SAVE_FEED_DETAILS": {
      const feedUpdates = {
        description: action.description,
        host_name: action.hostName,
        link: action.link,
      };
      [
        "category_1",
        "category_2",
        "chartable_prefix",
        "explicit",
        "language",
        "redirect",
        "use_podtrac_prefix",
      ].forEach((field) => {
        if (typeof action[field] !== "undefined") {
          feedUpdates[field] = action[field];
        }
      });
      return {
        ...state,
        feed: {
          ...state.feed,
          ...feedUpdates,
        },
      };
    }
    case "SAVE_MY_FEEDS":
      return {
        ...state,
        myFeeds: action.feeds,
      };
    case "SAVE_TEMPORARY_IMAGE":
      return {
        ...state,
        temporaryImage: action.newImage,
      };
    case "SAVE_FEED_EPISODES":
      return {
        ...state,
        feedEpisodes: action.feedEpisodes,
      };
    case "APPEND_FEED_EPISODE":
      return {
        ...state,
        feedEpisodes: [
          ...state.feedEpisodes,
          {
            ...action,
            feed_episode_id: action.newFeedEpisodeId,
            status: "uploading",
            progress: 0,
            ordering: action.ordering,
            publish_date: action.publishDate,
            drip_after: action.dripAfter,
            filename: action.filename,
            season: action.season,
          },
        ],
      };
    case "UPDATE_FEED_EPISODE_PROGRESS":
      return {
        ...state,
        feedEpisodes: [
          ...state.feedEpisodes.map((feedEpisode) =>
            feedEpisode.filename === action.filename
              ? { ...feedEpisode, progress: action.progress }
              : feedEpisode,
          ),
        ],
      };
    case "FINISH_FEED_EPISODE_UPLOAD":
      return {
        ...state,
        feedEpisodes: [
          ...state.feedEpisodes.map((feedEpisode) =>
            feedEpisode.feed_episode_id === action.feedEpisode.newFeedEpisodeId
              ? { ...feedEpisode, status: "pending" }
              : feedEpisode,
          ),
        ],
      };
    case "START_SINGLE_EPISODE_UPLOAD":
      return {
        ...state,
        singleEpisode: { progress: 0 },
      };
    case "UPDATE_SINGLE_EPISODE_PROGRESS":
      return {
        ...state,
        singleEpisode: { progress: action.progress },
      };
    case "FINISH_SINGLE_EPISODE_UPLOAD":
      return {
        ...state,
        singleEpisode: null,
      };
    case "START_ROLL_UPLOAD":
      return {
        ...state,
        rollUploads: {
          ...state.rollUploads,
          [`slot${action.slot}${action.position}`]: 0,
        },
      };
    case "UPDATE_ROLL_PROGRESS":
      return {
        ...state,
        rollUploads: {
          ...state.rollUploads,
          [`slot${action.slot}${action.position}`]: action.progress,
        },
      };
    case "FINISH_ROLL_UPLOAD":
      console.log(action);
      return {
        ...state,
        rollUploads: {
          ...state.rollUploads,
          [`slot${action.rollObject.slot}${action.rollObject.position}`]: null,
        },
      };
    case "SAVE_FEED_EPISODE_STATUS":
      return {
        ...state,
        feedEpisodes: [
          ...state.feedEpisodes.map((feedEpisode) =>
            feedEpisode.feed_episode_id === action.feedEpisodeId
              ? { ...feedEpisode, status: action.status }
              : feedEpisode,
          ),
        ],
      };
    case "UPDATE_FEED_EPISODE": {
      const reorderedEpisodes = [...state.feedEpisodes];
      const index = reorderedEpisodes.findIndex(
        (reoE) => reoE.feed_episode_id === action.feedEpisodeId,
      );
      const episode = reorderedEpisodes[index];
      if (episode) {
        episode.season = action.season;
        episode.ordering = action.ordering;
        reorderedEpisodes.splice(index, 1);
        reorderedEpisodes.splice(action.ordering - 1, 0, episode);
      }
      return {
        ...state,
        feedEpisodes: reorderedEpisodes,
      };
    }
    case "SAVE_STEP_INFO":
      return {
        ...state,
        activeEpisode: action.activeEpisode,
        publishedEpisode: action.publishedEpisode,
      };
    case "SAVE_FEED_EPISODE":
      return {
        ...state,
        feedEpisode: action.feedEpisode,
      };
    case "SAVE_LISTENERS": {
      if (action.page === 0) {
        return {
          ...state,
          listeners: {
            0: [...action.listeners],
            totalPages: action.totalPages,
          },
        };
      } else {
        return {
          ...state,
          listeners: {
            ...state.listeners,
            [action.page]: [...action.listeners],
          },
        };
      }
    }
    case "SAVE_SINGLE_LISTENER": {
      for (const [page, value] of Object.entries(state.listeners)) {
        const listenerIndex = value.findIndex(
          (row) => row.listener_id === action.listener.listener_id,
        );
        if (listenerIndex !== -1) {
          value[listenerIndex] = action.listener;
          return {
            ...state,
            listeners: { ...state.listeners, [page]: value },
          };
        }
      }
      return state;
    }
    case "SAVE_FEED_LISTENER_COUNT":
      return {
        ...state,
        listenerCount: action.listenerCount,
      };

    case "SAVE_UNIVERSAL_LINK_CODE":
      return {
        ...state,
        universalLink: action.universalLink,
      };
    case "SAVE_ALL_USERS":
      return {
        ...state,
        allUsers: action.users,
      };
    case "SAVE_ALL_STATISTICS":
      return {
        ...state,
        allStatistics: action.allStatistics,
      };
    case "SAVE_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
      };
    case "SAVE_INTEGRATIONS":
      return {
        ...state,
        integrations: action.integrations,
      };
    case "SAVE_EMAIL_INTEGRATIONS":
      return {
        ...state,
        emailIntegrations: action.integrations,
      };
    case "SAVE_SINGLE_INTEGRATION":
      return {
        ...state,
        integrations: { ...state.integrations, ...action.integration },
      };
    case "SAVE_THINKIFIC_COURSES":
      return {
        ...state,
        thinkificCourses: action.thinkificCourses,
      };
    case "SAVE_THINKIFIC_AUTH_ERROR":
      return {
        ...state,
        thinkificAuthError: action.value,
      };
    case "SAVE_INVOICES":
      return {
        ...state,
        invoices: action.invoices,
      };
    case "SAVE_INVOICE":
      return {
        ...state,
        nextInvoice: action.nextInvoice,
      };
    case "SHOW_CHANGE_PLAN":
      return {
        ...state,
        changePlanModal: true,
      };
    case "HIDE_CHANGE_PLAN":
      return {
        ...state,
        changePlanModal: false,
      };
    case "SAVE_STATISTICS":
      return {
        ...state,
        statistics: action.statistics,
      };
    case "SAVE_ACTION":
      return {
        ...state,
        myAction: action.myAction,
      };
    case "SAVE_DUPLICATE_ACTION":
      return {
        ...state,
        myAction: action.myAction,
        duplicateAction: true,
      };
    case "SAVE_ACTIONS":
      return {
        ...state,
        myActions: action.myActions,
      };
    case "SAVE_ACTION_INSTANCES":
      return {
        ...state,
        actionInstances: action.actionInstances,
      };
    case "SAVE_IMPORT_FEED_STATUS":
      return {
        ...state,
        feedImport: action.feedImport,
      };
    case "CLEAR_MY_ACTION":
      return {
        ...state,
        myAction: {},
      };
    case "SAVE_FEED_ROLLS": {
      const transformedFeedRolls = {};
      for (const feedRoll of action.feedRolls) {
        transformedFeedRolls[`slot${feedRoll.slot}${feedRoll.position}`] = feedRoll;
      }
      return {
        ...state,
        feedRolls: transformedFeedRolls,
      };
    }
    case "SAVE_FEED_SLOT_RULES": {
      const transformedFeedSlotRules = {
        slot1: {},
        slot2: {},
        slot3: {},
      };
      for (const feedSlotRule of action.feedSlotRules) {
        transformedFeedSlotRules[`slot${feedSlotRule.slot}`] = feedSlotRule;
      }
      return {
        ...state,
        feedSlotRules: transformedFeedSlotRules,
      };
    }
    case "SAVE_ZOOMS":
      return {
        ...state,
        zooms: action.zooms,
      };
    case "SAVE_AUDIO_INBOX_INTEGRATIONS":
      return {
        ...state,
        audioInboxIntegrations: action.audioInboxIntegrations,
      };
    case "SAVE_EXTERNAL_EPISODES": {
      const oldEpisodes = state.externalEpisodes;
      const newEpisodes = action.externalEpisodes;
      // check if new episodes are shorter implies sql has not indexed
      if (newEpisodes.length < oldEpisodes.length) {
        return state;
      }
      const mergedResults = newEpisodes.map((row) => {
        const match = oldEpisodes.find((oldRow) => {
          const destinationMatch = row.destination_feed_id === oldRow.destination_feed_id;
          const platformMatch = row.platform_episode_id === oldRow.platform_episode_id;
          return destinationMatch && platformMatch;
        });
        if (match) {
          row.deleted = match.deleted;
        }
        return row;
      });
      return {
        ...state,
        externalEpisodes: mergedResults,
      };
    }
    case "OPTIMISTIC_SAVE_EXTERNAL_EPISODES":
      return {
        ...state,
        externalEpisodes: [...state.externalEpisodes, ...action.externalEpisodes],
      };
    case "OPTIMISTIC_DELETE_EXTERNAL_EPISODE": {
      const tempExternalEpisode = state.externalEpisodes;
      console.log("old set:", state.externalEpisodes);
      console.log(
        "action vals dfID:",
        action.destinationFeedId,
        "plat form ep:",
        action.platformEpisodeId,
      );

      const newSet = tempExternalEpisode.map((row) => {
        console.log(
          "row vals dfID:",
          row.destination_feed_id,
          "plat form ep:",
          row.platform_episode_id,
        );
        const destinationMatch = row.destination_feed_id === action.destinationFeedId;
        const platformMatch = row.platform_episode_id === action.platformEpisodeId;
        if (!row.deleted) {
          row.deleted = destinationMatch && platformMatch;
        }
        return row;
      });
      console.log("new set:", newSet);
      return {
        ...state,
        externalEpisodes: newSet,
      };
    }
    case "SET_NICKNAME_OPTIMISTIC": {
      const oldState = state;
      const changeIndex = oldState.audioInboxIntegrations.findIndex(
        (row) => row.user_integration_token_id === action.userIntegrationTokenId,
      );
      oldState.audioInboxIntegrations[changeIndex].nickname = action.nickname;
      return {
        ...oldState,
      };
    }
    case "SAVE_AUDIO_INBOX_RECORDINGS": {
      const oldState = state;
      oldState.audioInboxRefresh++;

      if (action.loadMore) {
        const prevList = oldState.audioInboxRecordings[action.integrationId];
        oldState.audioInboxRecordings[action.integrationId] = [...prevList, ...action.recordings];
      } else {
        oldState.audioInboxRecordings[action.integrationId] = action.recordings;
      }
      oldState.audioInboxLoadMore[action.integrationId] = action.hasMore ? action.hasMore : false;
      return { ...oldState };
    }
    case "SAVE_AUDIO_INBOX_ERROR": {
      const oldState = state;
      oldState.audioInboxErrors[action.integrationId] = action.error;
      return { ...oldState };
    }
    case "SAVE_MY_SUPERLISTENERS":
      return {
        ...state,
        mySuperlisteners: action.listeners,
        mySuperlistenersTotal: action.total,
      };
    case "SAVE_SUPERLISTENER":
      return {
        ...state,
        superlistener: action.superlistener,
      };
    case "SAVE_TAG_SUGGESTIONS":
      return {
        ...state,
        tagSuggestions: action.tagSuggestions,
      };
    default:
      return state;
  }
}
