import { forwardRef, ComponentProps } from "react";

const HorizontalAdjustmentsIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>(
  (props, svgRef) => (
    <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 23.39V20.9h20v2.49H5Zm0-8.714h20v2.49H5v-2.49Zm0-6.224h20v2.49H5v-2.49Z"
        fill="#2E20B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 19.033a3.134 3.134 0 0 1-1.736-.524 3.1 3.1 0 0 1-.474-4.788 3.13 3.13 0 0 1 3.406-.675c.57.236 1.059.635 1.402 1.146a3.103 3.103 0 0 1-.388 3.93 3.132 3.132 0 0 1-2.21.911Zm-6.875-6.224a3.135 3.135 0 0 1-1.736-.524 3.1 3.1 0 0 1-.474-4.788 3.13 3.13 0 0 1 3.406-.675c.57.236 1.059.634 1.402 1.146a3.103 3.103 0 0 1-1.402 4.604c-.38.157-.786.237-1.196.237Zm1.875 9.336c0 .616-.183 1.217-.527 1.73a3.137 3.137 0 0 1-4.808.472 3.108 3.108 0 0 1-.677-3.393 3.115 3.115 0 0 1 1.15-1.396 3.134 3.134 0 0 1 3.947.387c.586.583.915 1.375.915 2.2Z"
        fill="#FB462A"
      />
    </svg>
  ),
);

export default HorizontalAdjustmentsIcon;
