import { Fragment, FunctionComponent } from "react";
import { toast as toastFacade, Toaster as ToasterBase, ToastBar } from "react-hot-toast";
import clsx from "clsx";

import { XMarkIcon } from "@heroicons/react/24/solid";

const BASE_CLASSES =
  "ha-toast tw-w-full tw-h-auto tw-border tw-border-solid tw-rounded tw-border-l-8 tw-px-5 tw-text-base tw-font-semibold";
const SUCCESS_CLASSES = "tw-border-green-500";
const ERROR_CLASSES = "tw-border-red-500";

const Toaster: FunctionComponent = () => {
  return (
    <ToasterBase
      toastOptions={{
        loading: {
          className: BASE_CLASSES,
        },
        success: {
          className: clsx(BASE_CLASSES, SUCCESS_CLASSES),
          iconTheme: {
            primary: "#22c55e", // green-500
            secondary: "#fff",
          },
        },
        error: {
          className: clsx(BASE_CLASSES, ERROR_CLASSES),
          iconTheme: {
            primary: "#ef4444", // red-500
            secondary: "#fff",
          },
        },
      }}
    >
      {(toast) => (
        <ToastBar toast={toast}>
          {({ icon, message }) => (
            <Fragment>
              {icon}
              {message}
              <button
                className="tw-flex tw-items-center tw-p-1 tw-bg-transparent tw-border-none tw-cursor-pointer focus:tw-outline-none"
                onClick={() => toastFacade.dismiss(toast.id)}
              >
                <XMarkIcon className="tw-w-4 tw-h-4" />
              </button>
            </Fragment>
          )}
        </ToastBar>
      )}
    </ToasterBase>
  );
};

export default Toaster;
