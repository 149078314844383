import clsx from "clsx";
import { AnchorHTMLAttributes, forwardRef, FunctionComponent } from "react";
import { NavLink as NavLinkBase, NavLinkProps as NavLinkBaseProps } from "react-router-dom";

interface NavLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "className"> {
  className?: NavLinkBaseProps["className"];
  as?: "a";
}
const NavLink: FunctionComponent<NavLinkProps> = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ className, href, children, as, ...htmlAnchorAttributes }, ref) => {
    if (htmlAnchorAttributes.target === "_blank" || as === "a") {
      const classString =
        typeof className === "function" ? className({ isActive: false }) : className;

      return (
        <a
          ref={ref}
          href={href}
          className={clsx(classString, "tw-cursor-pointer")}
          {...htmlAnchorAttributes}
        >
          {children}
        </a>
      );
    }

    return (
      <NavLinkBase
        ref={ref}
        to={{ pathname: href }}
        className={className}
        {...htmlAnchorAttributes}
      >
        {children}
      </NavLinkBase>
    );
  },
);

NavLink.displayName = "NavLink";

export default NavLink;
