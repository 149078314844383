import { forwardRef, InputHTMLAttributes, useId, ReactNode } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { Label } from "@helloaudio/ui";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  helpText?: string;
  helpTextColor?: string;
  label?: ReactNode;
  labelClassName?: string;
  preventSubmitOnEnter?: boolean;
  required?: boolean;
  type?: string;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      error,
      helpText,
      helpTextColor,
      label,
      labelClassName,
      preventSubmitOnEnter = false,
      required,
      type,
      ...inputHtmlAttributes
    },
    ref,
  ) => {
    const id = useId();
    if (preventSubmitOnEnter) {
      inputHtmlAttributes.onKeyDown = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      };
    }

    return (
      <div className={className}>
        {label && (
          <Label
            required={required}
            helpTextColor={helpTextColor}
            helpText={helpText}
            htmlFor={`input${id}`}
            className={clsx("tw-mb-2 tw-text-sm", labelClassName)}
          >
            {label}
          </Label>
        )}

        <div className="tw-relative">
          <input
            {...inputHtmlAttributes}
            ref={ref}
            id={`input${id}`}
            type={type}
            className={clsx(
              "ellipsis-input tw-transition-colors tw-duration-100 tw-form-input tw-block tw-w-full tw-border tw-border-solid tw-rounded tw-text-sm tw-font-Montserrat focus:tw-outline-none focus:tw-ring-0",
              !error
                ? "tw-placeholder-gray-400 tw-border-gray-300 focus:tw-border-indigo-800 focus:tw-bg-indigo-10"
                : "tw-text-red-900 tw-placeholder-red-300 tw-border-red-300 focus:tw-border-red-500 focus:tw-bg-red-10",
            )}
            aria-invalid={!!error}
            aria-errormessage={error && `input${id}error`}
          />
          {error && (
            <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none">
              <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true" />
            </div>
          )}
        </div>

        {error && (
          <p
            className="tw-mt-1 tw-mb-0 tw-text-sm tw-italic tw-text-red-600"
            id={`input${id}error`}
          >
            {error}
          </p>
        )}
      </div>
    );
  },
);

TextInput.displayName = "TextInput";

export default TextInput;
