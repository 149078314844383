import { useCallback, useState } from "react";

const useToggle = (initialState = false): [boolean, () => void, () => void, () => void] => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);
  const toggleOn = useCallback(() => {
    if (state === true) return;
    setState(true);
  }, [state]);
  const toggleOff = useCallback(() => {
    if (state === false) return;
    setState(false);
  }, [state]);

  return [state, toggle, toggleOn, toggleOff];
};

export default useToggle;
