import { ComponentProps, forwardRef } from "react";

const LogoutIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path d="M17.917 4.167H6.25v11.666h11.667V4.167Z" fill="#FB462A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.333 9.167H3.75L5 7.917l-.834-.834-2.5 2.5V10l2.5 2.5.834-.833-1.25-1.25h9.583v-1.25Z"
      fill="#2E20B1"
    />
  </svg>
));

export default LogoutIcon;
