import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* uploadImage({ photoBinaryString, contentType }) {
  yield put({ type: "SET_LOADING", key: "upload-image", loading: true });
  const postImageCall = yield apiCall();
  const result = yield postImageCall.post(makeUrl("/image"), {
    photoBinaryString,
    contentType,
  });
  yield put({ type: "SAVE_TEMPORARY_IMAGE", newImage: result.data.newImage });
  yield put({ type: "SET_LOADING", key: "upload-image", loading: false });
}
