import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import querystring from "querystring";

export function* getMyAction({ actionId, callback, duplicateAction }) {
  yield put({
    type: "SET_LOADING",
    key: `get-my-action-${actionId}`,
    loading: true,
  });
  const getMyActions = yield apiCall();
  const result = yield getMyActions.get(makeUrl("/action?" + querystring.encode({ actionId })));

  if (duplicateAction) {
    yield put({ type: "SAVE_DUPLICATE_ACTION", myAction: result.data });
  } else {
    yield put({ type: "SAVE_ACTION", myAction: result.data });
  }

  yield put({
    type: "SET_LOADING",
    key: `get-my-action-${actionId}`,
    loading: false,
  });
  callback();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

export function* getMyActions(silent) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-my-actions", loading: true });
  }
  const getMyActions = yield apiCall();
  const result = yield getMyActions.get(makeUrl("/my-actions"));
  yield put({ type: "SAVE_ACTIONS", myActions: result.data });
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-my-actions", loading: false });
  }
}

export function* createAction({
  actionId,
  active,
  activeChanged,
  ifConfiguration,
  thenConfiguration,
  callback,
}) {
  if (activeChanged === true) {
    yield put({
      type: "SET_LOADING",
      key: `action-status-${actionId}`,
      loading: true,
    });
  } else {
    yield put({ type: "SET_LOADING", key: "create-action", loading: true });
  }
  const createAction = yield apiCall();

  const result = yield createAction.post(makeUrl("/action"), {
    actionId,
    active,
    ifConfiguration,
    thenConfiguration,
  });

  if (activeChanged === true) {
    yield put({
      type: "SET_LOADING",
      key: `action-status-${actionId}`,
      loading: false,
    });
  } else {
    yield put({ type: "SAVE_ACTIONS", myActions: result.data });
    yield put({ type: "SET_LOADING", key: "create-action", loading: false });
    yield put({ type: "SET_LOADING", key: "form-reset", loading: true });
  }

  if (actionId) {
    yield put({
      type: "SET_SUCCESS",
      success: "Your action has been updated.",
    });
  } else {
    yield put({
      type: "SET_SUCCESS",
      success: "You have added a new Action to your account.",
    });
  }
  if (activeChanged !== true) {
    yield put({ type: "SET_LOADING", key: "form-reset", loading: false });
  }
  yield getMyActions({ silent: true });
  callback();
}

export function* deleteAction({ actionId, callback }) {
  const deleteAction = yield apiCall();
  yield deleteAction.delete(makeUrl("/action?") + querystring.encode({ actionId }));
  yield put({
    type: "SET_SUCCESS",
    success: "This action has been deleted from your account.",
  });
  yield getMyActions({ silent: true });
  callback();
}

export function* testAction({ actionId }) {
  const testAction = yield apiCall();
  yield testAction.post(makeUrl("/action/test?") + querystring.encode({ actionId }));
  yield put({
    type: "SET_SUCCESS",
    success: "Submitted a test action using your own email address as the listener email address.",
  });
}

export function* getMyActionInstances(silent) {
  if (!silent) {
    yield put({
      type: "SET_LOADING",
      key: "get-my-action-instances",
      loading: true,
    });
  }
  const getMyActions = yield apiCall();
  const result = yield getMyActions.get(makeUrl("/my-action-instances"));
  yield put({
    type: "SAVE_ACTION_INSTANCES",
    actionInstances: result.data.actionInstances,
  });
  if (!silent) {
    yield put({
      type: "SET_LOADING",
      key: "get-my-action-instances",
      loading: false,
    });
  }
}
