import { forwardRef, ComponentProps } from "react";

const SpeakerphoneIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.405 20.405.85 4.604 3.96-.009L10 20l-3.595.405Zm4.22-.405L8.75 13.75 20.323 5l4.052 16.875L10.625 20Z"
      fill="#2E20B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.75 13.75 8.125-6.25L20 21.25 10.625 20 8.75 13.75Zm-4.375 5c-.396-1.588.399-3.324 1.875-3.75l2.5-1.25L10.625 20l-2.5.625c-1.476.426-3.354-.286-3.75-1.875Zm18.637-2.552-1.45-6.039c.303-.1.62-.155.938-.159a3.12 3.12 0 0 1 .512 6.198Z"
      fill="#FB462A"
    />
  </svg>
));

export default SpeakerphoneIcon;
