import { Fragment, FunctionComponent, useMemo } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { Menu } from "@headlessui/react";
import { ChevronDownIcon, FingerPrintIcon } from "@heroicons/react/24/solid";
import { PlusCircleIcon } from "@helloaudio/icons/outline";
import {
  BellIcon,
  CogIcon,
  LogoutIcon,
  SearchIcon,
  UserIcon,
  BillingIcon,
} from "@helloaudio/icons/twotone";

import { Avatar } from "@helloaudio/ui";
import { auth } from "@helloaudio/auth";
import { useLocalUserQuery } from "@helloaudio/api";
import { NavLink } from "@helloaudio/router";

const Header: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { data: localUser } = useLocalUserQuery();

  const userNavigation = useMemo(
    () => [
      {
        icon: UserIcon,
        label: "My Account",
        href: "/account",
      },
      {
        icon: BillingIcon,
        label: "Subscription",
        href: "/subscription",
      },
      {
        icon: FingerPrintIcon,
        label: "End Spoofing",
        as: "a",
        tabIndex: 0,
        hidden: !localUser!.spoofingUser,
        onClick: () => {
          dispatch({ type: "SPOOF" });
        },
      },
      {
        icon: CogIcon,
        label: "Admin Tools",
        href: "/admin-tools",
        hidden: !localUser!.superuser,
      },
      {
        icon: LogoutIcon,
        label: "Logout",
        as: "a",
        tabIndex: 0,
        onClick: () => auth.logout(),
      },
    ],
    [localUser],
  );

  return (
    <header
      id="ha_header"
      className="tw-fixed tw-z-header tw-top-16 tw-left-0 tw-right-0 tw-px-4 md:tw-top-0 md:tw-ml-16 ha-background"
    >
      {/* Top row */}
      <div className="tw-h-16 tw-flex tw-items-center tw-justify-between">
        {/* Search input */}
        {/* <div className="tw-flex tw-items-center tw-gap-2">
          <SearchIcon className="tw-w-[25px] tw-h-8" />
          <input
            className="tw-form-input tw-w-28 tw-font-medium placeholder:tw-font-normal tw-p-0 tw-pb-1 tw-border-0 tw-border-b tw-border-slate-300 tw-bg-transparent hover:tw-border-indigo-800 focus:tw-border-indigo-800 focus:tw-ring-0 md:tw-w-36 search-placeholder-text-sm"
            placeholder="Search..."
          />
        </div> */}

        {/* Create New Feed button */}
        <NavLink
          href="/create-feed"
          className="tw-cursor-pointer tw-inline-flex tw-shrink-0 tw-gap-2 tw-pl-2 tw-pr-3 tw-py-1 tw-rounded tw-border-none tw-bg-transparent hover:tw-bg-slate-200 tw-text-sm tw-text-indigo-800 hover:tw-text-indigo-800 hover:tw-no-underline tw-font-Montserrat tw-font-semibold tw-items-center focus-visible:tw-bg-slate-200 focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500 md:tw-ml-auto md:tw-mr-3"
        >
          <PlusCircleIcon className="tw-h-[24px] tw-block" />
          Create New Feed
        </NavLink>

        {/* Notifications button */}
        {/* <button className="tw-mr-3 tw-p-1 tw-rounded tw-border-none tw-bg-transparent hover:tw-bg-slate-200 focus:tw-outline-none focus-visible:tw-bg-slate-200 focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500 tw-hidden sm:tw-block">
          <BellIcon className="tw-w-[24px] tw-h-8" />
        </button> */}

        {/* Usermenu dropdown button */}
        <Menu as="div" className="tw-relative tw-hidden md:tw-block">
          {({ open }) => (
            <Fragment>
              <Menu.Button
                className={clsx(
                  "tw-flex tw-items-center tw-justify-between tw-py-1 tw-pl-2 tw-pr-1 tw-rounded tw-border-none tw-bg-transparent hover:tw-bg-slate-200 focus:tw-outline-none focus-visible:tw-bg-slate-200 focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500",
                  open && "tw-bg-slate-200",
                )}
              >
                <Avatar
                  className="tw-mr-2"
                  name={localUser!.last_known_name ?? ""}
                  imageUrl={localUser!.last_known_profile_photo ?? ""}
                  imageAltText={`${localUser!.last_known_name}'s profile picture`}
                />
                <span className="tw-text-sm tw-font-semibold tw-text-black tw-mr-2">
                  {localUser!.last_known_name}
                </span>
                <ChevronDownIcon className="tw-shrink-0 tw-w-5 tw-h-5 tw-text-indigo-800" />
              </Menu.Button>
              <Menu.Items
                as="nav"
                className="tw-shadow tw-absolute tw-w-48 tw-mt-3 tw-rounded tw-right-0 tw-bg-white focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500"
              >
                <ul className="tw-list-none tw-flex tw-flex-col tw-px-0 tw-py-3 tw-m-0">
                  {userNavigation.map(
                    ({ icon: Icon, label, hidden, as, ...htmlAnchorAttributes }, index) =>
                      !hidden && (
                        <li key={index}>
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                {...htmlAnchorAttributes}
                                as={as as "a"}
                                className={clsx(
                                  "tw-h-8 tw-px-5 hover:tw-no-underline hover:tw-text-black tw-flex tw-items-center tw-text-sm tw-font-semibold tw-text-black",
                                  active ? "tw-bg-slate-100" : "tw-bg-white",
                                )}
                              >
                                <Icon className="tw-mr-2 tw-w-5 tw-h-5 tw-shrink-0" />
                                <span
                                  className="tw-whitespace-nowrap"
                                  style={{ marginLeft: label === "Billing" ? "-3px" : "" }}
                                >
                                  {label}
                                </span>
                              </NavLink>
                            )}
                          </Menu.Item>
                        </li>
                      ),
                  )}
                </ul>
              </Menu.Items>
            </Fragment>
          )}
        </Menu>
      </div>

      {/* Bottom row */}
      {/* <div className="tw-p-4 tw-pt-0">
        <Row>
          <Col className="tw-p-0 d-flex tw-items-center">
            <ChevronLeftIcon className="tw-shrink-0 tw-w-5 tw-h-5 tw-text-indigo-800" />
            <Link
              to="/create-feed"
            >
              <span>Back to Feed Public / Private Section</span>
            </Link>
          </Col>
          <Col className="d-flex justify-content-center">
            <span>Create Feed</span>
          </Col>
          <Col>

          </Col>
        </Row>
      </div> */}
    </header>
  );
};

export default Header;
