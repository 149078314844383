import { auth } from "@helloaudio/auth";

import GuardFunction from "../GuardFunction";

/**
 * Requires users not to be authenticated.
 * Authenticated users will be redirected to "/" path.
 *
 * @returns boolean
 */
const guestGuard: GuardFunction = async ({ navigate }) => {
  const isAuthenticated = await auth.isAuthenticated();
  if (isAuthenticated) {
    navigate("/", {
      replace: true,
    });
    return false;
  }

  return true;
};

export default guestGuard;
