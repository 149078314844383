import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import querystring from "querystring";

export function* getAllUsers({ planFilter = "", emailFilter = "", nameFilter = "" }) {
  yield put({ type: "SET_LOADING", key: "get-all-users", loading: true });
  const getAllUsersCall = yield apiCall();
  const result = yield getAllUsersCall.get(
    makeUrl("/admin/users?") + querystring.encode({ planFilter, emailFilter, nameFilter }),
  );
  const users = result.data.users;
  yield put({ type: "SAVE_ALL_USERS", users: users });
  yield put({ type: "SET_LOADING", key: "get-all-users", loading: false });
}
