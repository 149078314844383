import { lazy } from "react";

export { default as Homepage } from "./Homepage";
export const LazyHomepage = lazy(() => import("./Homepage"));

export { default as Subcription } from "./SubscriptionPage/SubscriptionPage";
export const LazySubscription = lazy(() => import("./SubscriptionPage/SubscriptionPage"));

export { default as ExternalStats } from "./ExternalStats";
export const LazyExternalStats = lazy(() => import("./ExternalStats"));

export { default as Checkout } from "./CheckoutPage/CheckoutPage";
export const LazyCheckout = lazy(() => import("./CheckoutPage/CheckoutPage"));

export { default as MyFeeds } from "./MyFeeds";
export const LazyMyFeeds = lazy(() => import("./MyFeeds"));

export { default as Superlisteners } from "./Superlisteners";
export const LazySuperlisteners = lazy(() => import("./Superlisteners"));

export { default as Actions } from "./Actions";
export const LazyActions = lazy(() => import("./Actions"));

export { default as Affiliate } from "./Affiliate";
export const LazyAffiliate = lazy(() => import("./Affiliate"));

export { default as Integrations } from "./Integrations/Integrations";
export const LazyIntegrations = lazy(() => import("./Integrations/Integrations"));

export { default as AudioInbox } from "./AudioInbox";
export const LazyAudioInbox = lazy(() => import("./AudioInbox"));

export { default as AudioInboxPlatform } from "./AudioInbox/Platform";
export const LazyAudioInboxPlatform = lazy(() => import("./AudioInbox/Platform"));

export { default as FeedType } from "./CreateFeed/FeedType";
export const LazyFeedType = lazy(() => import("./CreateFeed/FeedType"));

export { default as CreateFeed } from "./CreateFeed/CreateFeed";
export const LazyCreateFeed = lazy(() => import("./CreateFeed/CreateFeed"));

export { default as Account } from "./Account";
export const LazyAccount = lazy(() => import("./Account"));

export { default as AdminTools } from "./AdminTools/AdminTools";
export const LazyAdminTools = lazy(() => import("./AdminTools/AdminTools"));

export { default as Profile } from "./Profile";
export const LazyProfile = lazy(() => import("./Profile"));

export { default as Feed } from "./Feed/Feed";
export const LazyFeed = lazy(() => import("./Feed/Feed"));

export { default as BillingInfo } from "./Billing/BillingInfo";
export const LazyBillingInfo = lazy(() => import("./Billing/BillingInfo"));

export { default as Episode } from "./Episode/Episode";
export const LazyEpisode = lazy(() => import("./Episode/Episode"));

export { default as DescriptSelectFeed } from "./DescriptSelectFeed";
export const LazyDescriptSelectFeed = lazy(() => import("./DescriptSelectFeed"));

export { default as Superlistener } from "./Superlistener";
export const LazySuperlistener = lazy(() => import("./Superlistener"));

export { default as ManageFeedSignup } from "./ManageFeedSignup/ManageFeedSignup";
export const LazyManageFeedSignup = lazy(() => import("./ManageFeedSignup/ManageFeedSignup"));

export { default as FeedSignup } from "./FeedSignup/FeedSignup";
export const LazyFeedSignup = lazy(() => import("./FeedSignup/FeedSignup"));

export { default as FeedSignupBillingDashboardLogin } from "./FeedSignupBillingDashboardLogin/FeedSignupBillingDashboardLogin";
export const LazyFeedSignupBillingDashboardLogin = lazy(
  () => import("./FeedSignupBillingDashboardLogin/FeedSignupBillingDashboardLogin"),
);
