import { ComponentProps, forwardRef } from "react";

const UserIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.333 17.083 2.424-4.537 1.819-.755 1.212-2.268h2.424l1.212 2.268 1.818.757 2.425 4.535H3.333Z"
      fill="#FB462A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m13.333 7.083-.416 1.25H12.5l-.417 1.25-1.666 1.25h-.834l-1.666-1.25-.417-1.25h-.417l-.416-1.25H7.5l-.417-2.5L10 2.917l2.917 1.666-.417 2.5h.833Z"
      fill="#2E20B1"
    />
  </svg>
));

export default UserIcon;
