import { ComponentProps, forwardRef } from "react";

const CogIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.95 5.05.8-2.55h2.5l.8 2.55h-4.1ZM7.91 15l.808 2.5h2.564l.808-2.5H7.91Zm7.04-7.05 2.55.8v2.5l-2.55.8v-4.1ZM5 7.91l-2.5.808v2.564l2.5.808V7.91Zm7.05-2.86 2.37-1.237 1.767 1.768L14.95 7.95l-2.899-2.9Zm-7.064 7.01-1.194 2.337 1.81 1.811 2.339-1.195-2.955-2.954Zm9.963-.01 1.238 2.37-1.768 1.767-2.369-1.238 2.9-2.899ZM7.941 4.986 5.603 3.792l-1.811 1.81 1.196 2.339L7.94 4.986Z"
      fill="#2E20B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 15.417a5.417 5.417 0 1 1 0-10.834 5.417 5.417 0 0 1 0 10.834Zm0-7.448a2.031 2.031 0 1 0 0 4.062 2.031 2.031 0 0 0 0-4.062Z"
      fill="#FB462A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0-5.714a1.714 1.714 0 1 0 0 3.428 1.714 1.714 0 0 0 0-3.428Z"
      fill="#2E20B1"
    />
  </svg>
));

export default CogIcon;
