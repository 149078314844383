import { FunctionComponent, ReactNode } from "react";
import { Switch } from "@headlessui/react";
import clsx from "clsx";

interface ToggleProps {
  checked?: boolean;
  className?: string;
  description?: string;
  disabled?: boolean;
  id?: string;
  label?: ReactNode;
  labelClassName?: string;
  onChange: (value: boolean) => void;
  value: boolean;
}

const Toggle: FunctionComponent<ToggleProps> = ({
  className,
  description,
  disabled,
  label,
  labelClassName,
  onChange,
  value,
}) => {
  const switchStyles = [
    "tw-p-0",
    "tw-relative",
    "tw-inline-flex",
    "tw-flex-shrink-0",
    "tw-h-5",
    "tw-w-9",
    "tw-border-2",
    "tw-border-transparent",
    "tw-rounded-full",
    "tw-transition-colors",
    "tw-ease-in-out",
    "tw-duration-200",
    "focus:tw-outline-none",
    "focus:tw-ring-2",
    "focus:tw-ring-offset-0",
    "focus:tw-ring-green-300",
  ];
  const switchStylesChecked = ["tw-cursor-pointer, tw-bg-green-500"];
  const switchStylesUnchecked = ["tw-cursor-pointer, tw-bg-gray-200"];
  const switchStylesDisabled = ["tw-cursor-not-allowed tw-opacity-75"];
  return (
    <Switch.Group as="div" className={clsx(className, "tw-flex tw-items-center")}>
      <Switch
        checked={!!value}
        onChange={onChange}
        className={clsx(
          switchStyles,
          value ? switchStylesChecked : switchStylesUnchecked,
          disabled ? switchStylesDisabled : [],
        )}
        disabled={disabled ? true : false}
      >
        <span
          aria-hidden="true"
          className={clsx(
            value ? "tw-translate-x-4" : "tw-translate-x-0",
            "tw-pointer-events-none tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-bg-white tw-shadow tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200",
          )}
        />
      </Switch>
      {(label || description) && (
        <Switch.Label
          as="span"
          className={clsx(
            "tw-text-base tw-ml-3",
            disabled ? "tw-cursor-default" : "tw-cursor-pointer",
          )}
        >
          {label && (
            <span className={clsx("tw-font-semibold tw-text-black", labelClassName)}>{label}</span>
          )}

          {description && (
            <span className={clsx("tw-text-gray-500", label && "tw-ml-2")}>{description}</span>
          )}
        </Switch.Label>
      )}
    </Switch.Group>
  );
};

export default Toggle;
