import { forwardRef, ComponentProps } from "react";

const LightningBoltIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.625 5.46h-7.599L8.75 14.175h3.75l-5 11.204 15-11.826h-5.625l3.75-8.091Z"
      fill="#FB462A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.763 17.286 7.5 25.378l3.611-8.092h6.652Z"
      fill="#2E20B1"
    />
  </svg>
));

export default LightningBoltIcon;
