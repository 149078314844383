import { Fragment, FunctionComponent, useReducer, Reducer, Dispatch, useMemo, useRef } from "react";
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { useOnClickOutside } from "@helloaudio/hooks";
import { Label } from "@helloaudio/ui";

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type State = {
  visible: boolean;
  selector: "year" | "month" | "date";
  year: number;
  month: number;
};

type Action =
  | { type: "SET_VISIBLE"; payload: boolean }
  | { type: "SET_SELECTOR"; payload: "year" | "month" | "date" }
  | { type: "SET_YEAR"; payload: number | "next" | "previous" }
  | { type: "SET_MONTH"; payload: number | "next" | "previous" };

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "SET_VISIBLE":
      return { ...state, visible: action.payload };
    case "SET_SELECTOR":
      return { ...state, selector: action.payload };
    case "SET_YEAR":
      if (action.payload === "next") {
        return {
          ...state,
          year: state.year + 1,
        };
      }
      if (action.payload === "previous") {
        return {
          ...state,
          year: state.year - 1,
        };
      }
      return {
        ...state,
        year: action.payload,
      };
    case "SET_MONTH":
      if (action.payload === "next") {
        return {
          ...state,
          year: state.month === 11 ? state.year + 1 : state.year,
          month: state.month === 11 ? 0 : state.month + 1,
        };
      }
      if (action.payload === "previous") {
        return {
          ...state,
          year: state.month === 0 ? state.year - 1 : state.year,
          month: state.month === 0 ? 11 : state.month - 1,
        };
      }
      return {
        ...state,
        month: action.payload,
      };
    default:
      return state;
  }
};

const YearSelector: FunctionComponent<{
  state: State;
  dispatch: Dispatch<Action>;
}> = ({ state, dispatch }) => {
  const decadeStartYear = useMemo(() => state.year - (state.year % 10), [state.year]);
  const years = useMemo(() => {
    const years = [];
    for (let i = 0; i <= 9; i++) years.push(decadeStartYear + i);
    return years;
  }, [decadeStartYear]);

  return state.selector === "year" ? (
    <Fragment>
      {/* Header */}
      <div className="tw-flex tw-justify-between tw-p-5">
        <button
          className="tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-p-0 tw-border-0 tw-rounded-full tw-bg-white tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
          onClick={() => dispatch({ type: "SET_YEAR", payload: decadeStartYear - 10 })}
        >
          <ChevronLeftIcon className="tw-w-4 tw-h-4" />
        </button>
        <button className="tw-px-2 tw-flex tw-justify-center tw-items-center tw-text-sm tw-text-indigo-800 tw-font-medium tw-bg-white tw-border-0 tw-rounded hover:tw-bg-gray-100 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1">
          {years[0]}-{years[years.length - 1]}
        </button>
        <button
          className="tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-p-0 tw-border-0 tw-rounded-full tw-bg-white tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
          onClick={() => dispatch({ type: "SET_YEAR", payload: decadeStartYear + 10 })}
        >
          <ChevronRightIcon className="tw-w-4 tw-h-4" />
        </button>
      </div>

      <div className="tw-px-5 tw-pb-5 ">
        <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-w-64">
          {years.map((year) => (
            <button
              key={year}
              className="tw-py-2 tw-flex tw-justify-center tw-items-center tw-text-sm tw-text-gray-900 tw-font-normal tw-bg-white tw-border-0 tw-rounded hover:tw-bg-gray-100 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
              onClick={() => {
                dispatch({ type: "SET_YEAR", payload: year });
                dispatch({ type: "SET_SELECTOR", payload: "month" });
              }}
            >
              {year}
            </button>
          ))}
        </div>
      </div>
    </Fragment>
  ) : null;
};

const MonthSelector: FunctionComponent<{
  state: State;
  dispatch: Dispatch<Action>;
}> = ({ state, dispatch }) => {
  return state.selector === "month" ? (
    <Fragment>
      {/* Header */}
      <div className="tw-flex tw-justify-between tw-p-5">
        {/* Previous button */}
        <button
          className="tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-p-0 tw-border-0 tw-rounded-full tw-bg-white tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
          onClick={() => dispatch({ type: "SET_YEAR", payload: "previous" })}
        >
          <ChevronLeftIcon className="tw-w-4 tw-h-4" />
        </button>

        {/* Year selector button */}
        <button
          className="tw-px-2 tw-flex tw-justify-center tw-items-center tw-text-sm tw-text-indigo-800 tw-font-medium tw-bg-white tw-border-0 tw-rounded hover:tw-bg-gray-100 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
          onClick={() => dispatch({ type: "SET_SELECTOR", payload: "year" })}
        >
          {state.year}
        </button>

        {/* Next button */}
        <button
          className="tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-p-0 tw-border-0 tw-rounded-full tw-bg-white tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
          onClick={() => dispatch({ type: "SET_YEAR", payload: "next" })}
        >
          <ChevronRightIcon className="tw-w-4 tw-h-4" />
        </button>
      </div>

      {/* Body */}
      <div className="tw-px-5 tw-pb-5">
        <div className="tw-w-64 tw-grid tw-grid-cols-4 tw-gap-2">
          {monthNames.map((monthName, month) => (
            <button
              key={month}
              className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-text-sm tw-text-gray-900 tw-font-normal tw-bg-white tw-border-0 tw-rounded hover:tw-bg-gray-100 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
              onClick={() => {
                dispatch({ type: "SET_MONTH", payload: month });
                dispatch({ type: "SET_SELECTOR", payload: "date" });
              }}
            >
              {monthName.substring(0, 3)}
            </button>
          ))}
        </div>
      </div>
    </Fragment>
  ) : null;
};

const DateSelector: FunctionComponent<{
  state: State;
  dispatch: Dispatch<Action>;
  value: Date;
  onChange: (value: Date) => void;
}> = ({ state, dispatch, value, onChange }) => {
  const [previousMonthDates, currentMonthDates, nextMonthDates] = useMemo(() => {
    const currentMonthStartsAtIndex = new Date(state.year, state.month, 1).getDay();
    const lastDateOfCurrentMonth = new Date(state.year, state.month + 1, 0).getDate();
    const lastDateOfPreviousMonth = new Date(state.year, state.month, 0).getDate();

    const previousMonthDates = [];
    const currentMonthDates = [];
    const nextMonthDates = [];

    for (let i = 0; i < 42; i++) {
      // fill previous month
      if (i < currentMonthStartsAtIndex) {
        previousMonthDates.push({
          year: state.month === 0 ? state.year - 1 : state.year,
          month: state.month === 0 ? 11 : state.month - 1,
          date: lastDateOfPreviousMonth - currentMonthStartsAtIndex + i + 1,
        });
      }

      // fill current month
      if (
        i >= currentMonthStartsAtIndex &&
        i <= lastDateOfCurrentMonth + currentMonthStartsAtIndex - 1
      ) {
        currentMonthDates.push({
          year: state.year,
          month: state.month,
          date: i - currentMonthStartsAtIndex + 1,
        });
      }

      // fill next month
      if (i > lastDateOfCurrentMonth + currentMonthStartsAtIndex - 1) {
        nextMonthDates.push({
          year: state.month === 11 ? state.year + 1 : state.year,
          month: state.month === 11 ? 0 : state.month + 1,
          date: i - lastDateOfCurrentMonth - currentMonthStartsAtIndex + 1,
        });
      }
    }

    return [previousMonthDates, currentMonthDates, nextMonthDates];
  }, [state.year, state.month]);

  return state.selector === "date" ? (
    <Fragment>
      {/* Header */}
      <div className="tw-flex tw-justify-between tw-p-5">
        {/* Previous button */}
        <button
          className="tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-p-0 tw-border-0 tw-rounded-full tw-bg-white tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
          onClick={() => dispatch({ type: "SET_MONTH", payload: "previous" })}
        >
          <ChevronLeftIcon className="tw-w-4 tw-h-4" />
        </button>

        {/* Month selector button */}
        <button
          className="tw-px-2 tw-flex tw-justify-center tw-items-center tw-text-sm tw-text-indigo-800 tw-font-medium tw-bg-white tw-border-0 tw-rounded hover:tw-bg-gray-100 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
          onClick={() => dispatch({ type: "SET_SELECTOR", payload: "month" })}
        >
          {monthNames[state.month]} {state.year}
        </button>

        {/* Next button */}
        <button
          className="tw-flex tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-p-0 tw-border-0 tw-rounded-full tw-bg-white tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus-visible:tw-bg-gray-100 focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1"
          onClick={() => dispatch({ type: "SET_MONTH", payload: "next" })}
        >
          <ChevronRightIcon className="tw-w-4 tw-h-4" />
        </button>
      </div>

      {/* Body */}
      <div className="tw-px-5 tw-pb-5">
        {/* Day names */}
        <div className="tw-grid tw-grid-cols-7 tw-gap-2 tw-mb-2">
          {dayNames.map((dayName) => (
            <span key={dayName} className="tw-text-center tw-text-sm tw-text-gray-400">
              {dayName.substring(0, 3)}
            </span>
          ))}
        </div>

        {/* Dates */}
        <div className="tw-grid tw-grid-cols-7 tw-gap-2 tw-w-64">
          {previousMonthDates.map(({ year, month, date }) => (
            <button
              key={`${year}${month}${date}`}
              onClick={() => {
                dispatch({ type: "SET_MONTH", payload: "previous" });
                onChange(new Date(year, month, date));
              }}
              className={clsx(
                "tw-justify-self-center tw-w-7 tw-h-7 tw-border-0 tw-rounded-full tw-text-sm",
                "focus:tw-outline-none focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1",
                value.getFullYear() === year &&
                  value.getMonth() === month &&
                  value.getDate() === date
                  ? "tw-bg-indigo-300 tw-text-white focus-visible:tw-ring-offset-2"
                  : "tw-bg-white tw-text-gray-300 hover:tw-bg-gray-100 focus-visible:tw-bg-gray-100",
              )}
            >
              {date}
            </button>
          ))}
          {currentMonthDates.map(({ year, month, date }) => (
            <button
              key={`${year}${month}${date}`}
              onClick={() => onChange(new Date(year, month, date))}
              className={clsx(
                "tw-justify-self-center tw-w-7 tw-h-7 tw-border-0 tw-rounded-full tw-text-sm",
                "focus:tw-outline-none focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1",
                value.getFullYear() === year &&
                  value.getMonth() === month &&
                  value.getDate() === date
                  ? "tw-bg-indigo-800 tw-text-white focus-visible:tw-ring-offset-2"
                  : "tw-bg-white tw-text-gray-900 hover:tw-bg-gray-100 focus-visible:tw-bg-gray-100",
              )}
            >
              {date}
            </button>
          ))}
          {nextMonthDates.map(({ year, month, date }) => (
            <button
              key={`${year}${month}${date}`}
              onClick={() => {
                dispatch({ type: "SET_MONTH", payload: "next" });
                onChange(new Date(year, month, date));
              }}
              className={clsx(
                "tw-justify-self-center tw-w-7 tw-h-7 tw-border-0 tw-rounded-full tw-text-sm",
                "focus:tw-outline-none focus-visible:tw-ring-indigo-800 focus-visible:tw-ring-1",
                value.getFullYear() === year &&
                  value.getMonth() === month &&
                  value.getDate() === date
                  ? "tw-bg-indigo-300 tw-text-white focus-visible:tw-ring-offset-2"
                  : "tw-bg-white tw-text-gray-300 hover:tw-bg-gray-100 focus-visible:tw-bg-gray-100",
              )}
            >
              {date}
            </button>
          ))}
        </div>

        {/* <div>time select</div> */}
      </div>
    </Fragment>
  ) : null;
};

interface DateInputProps {
  className?: string;
  label?: string;
  value: Date;
  onChange: (value: Date) => void;
}
const DateInput: FunctionComponent<DateInputProps> = ({ className, label, value, onChange }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const outsideClickDivRef = useRef<HTMLDivElement>(null);

  const [state, dispatch] = useReducer(reducer, {
    visible: false,
    selector: "date",
    year: value.getFullYear(),
    month: value.getMonth(),
  });

  useOnClickOutside(outsideClickDivRef, () => {
    dispatch({ type: "SET_VISIBLE", payload: false });
  });

  return (
    <div className={className}>
      {label && (
        <Label onClick={() => buttonRef.current?.focus()} className="tw-mb-2">
          {label}
        </Label>
      )}

      <div ref={outsideClickDivRef} className="tw-relative">
        <button
          ref={buttonRef}
          onClick={() => dispatch({ type: "SET_VISIBLE", payload: !state.visible })}
          className="tw-transition-colors tw-duration-100 tw-relative tw-w-full tw-border-solid tw-border tw-rounded tw-pl-3 tw-pr-10 tw-py-2 tw-text-left tw-cursor-default focus:tw-outline-none focus:tw-ring-0 focus:tw-bg-indigo-10 focus:tw-border-indigo-800 sm:tw-text-sm tw-border-gray-300 tw-bg-white"
        >
          <span className="tw-block tw-truncate">
            {value.toLocaleDateString()} {value.toLocaleTimeString()}
          </span>
          <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none">
            <CalendarIcon className="tw-h-5 tw-w-5 tw-text-gray-400" />
          </span>
        </button>

        {state.visible && (
          <div className="tw-absolute tw-mt-1 tw-z-dropdown tw-bg-white tw-border-solid tw-border tw-border-gray-300 tw-rounded tw-shadow-lg">
            <div className="tw-flex tw-h-full">
              {/* <div className="tw-pl-8">
              <ul className="tw-list-none tw-m-0 tw-p-0 tw-space-y-1">
                <li>Today</li>
                <li>Tomorrow</li>
                <li>In a week</li>
              </ul>
            </div> */}

              <div className="tw-grow">
                <YearSelector state={state} dispatch={dispatch} />
                <MonthSelector state={state} dispatch={dispatch} />
                <DateSelector state={state} dispatch={dispatch} value={value} onChange={onChange} />
              </div>

              {/* <div className="tw-grow">calendar 2</div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateInput;
