import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import querystring from "querystring";

export function* resendLink({ listenerId, callback }) {
  const key = `resend-link-${listenerId}`;
  yield put({ type: "SET_LOADING", key, loading: true });
  const resendLinkCall = yield apiCall();
  yield resendLinkCall.post(makeUrl("/resend"), {
    listenerId,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({ type: "SET_SUCCESS", success: "Link has been resent" });
  if (callback) {
    callback();
  }
}

export function* getUniversalLink({ feedId }) {
  const getListenerCountCall = yield apiCall();
  const results = yield getListenerCountCall.get(
    makeUrl("/feed-universal-link?") + querystring.encode({ feedId }),
  );
  yield put({ type: "SAVE_UNIVERSAL_LINK_CODE", universalLink: results.data.universalLink });
}

export function* regenerateLink({ feedId, callback }) {
  const key = "regenerate-link";
  yield put({ type: "SET_LOADING", key, loading: true });
  const regenerateLinkCall = yield apiCall();
  yield regenerateLinkCall.post(makeUrl("/feed/regenerate"), {
    feedId,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({
    type: "SET_SUCCESS",
    success: "Your feed's Universal Link has been regenerated",
  });
  if (callback) {
    yield callback();
  }
  yield getUniversalLink({ feedId });
}
