import { forwardRef, InputHTMLAttributes, useId } from "react";
import clsx from "clsx";

import { Label } from "@helloaudio/ui";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  dangerouslySetLabel?: boolean;
  description?: string;
  helpText?: string;
  label?: string;
  labelClassName?: string;
  labelWeight?:
    | "thin"
    | "extralight"
    | "light"
    | "normal"
    | "medium"
    | "semibold"
    | "bold"
    | "extrabold"
    | "black";
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      dangerouslySetLabel,
      description,
      disabled,
      helpText,
      label,
      labelClassName,
      labelWeight,
      ...htmlInputAttributes
    },
    ref,
  ) => {
    const id = useId();

    return (
      <div className={clsx("tw-flex tw-items-start tw-font-Montserrat", className)}>
        <div className="tw-flex tw-items-center">
          <input
            {...htmlInputAttributes}
            disabled={disabled}
            ref={ref}
            id={`input${id}`}
            type="checkbox"
            className={clsx(
              "tw-form-checkbox tw-cursor-pointer focus:tw-ring-green-300 focus:tw-ring-offset-0 tw-h-5 tw-w-5 tw-border-solid tw-border-gray-300 tw-rounded-sm",
              disabled ? "tw-text-gray-300" : "tw-text-green-500",
            )}
            aria-describedby={`input${id}description`}
          />
        </div>
        <div className="tw-ml-3 tw-text-sm">
          {label && (
            <Label
              className={clsx("tw-cursor-pointer tw-mb-0", labelClassName)}
              dangerouslySetInnerHTML={dangerouslySetLabel ? { __html: label } : undefined}
              helpText={helpText}
              htmlFor={`input${id}`}
              weight={labelWeight}
            >
              {!dangerouslySetLabel ? label : undefined}
            </Label>
          )}
          {!label && description && (
            <label
              id={`input${id}description`}
              htmlFor={`input${id}`}
              className={clsx("tw-cursor-pointer tw-mb-0 tw-text-gray-500", labelClassName)}
            >
              {description}
            </label>
          )}
          {label && description && (
            <p
              id={`input${id}description`}
              className={clsx("tw-mb-0 tw-text-gray-500", labelClassName)}
            >
              {description}
            </p>
          )}
        </div>
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
