import { useQuery } from "@tanstack/react-query";
import z from "zod";

import { auth } from "@helloaudio/auth";
import { store } from "@helloaudio/redux";
import { useDispatch } from "react-redux";

import apiClient from "../apiClient";

/**
 *
 */
const responseValidator = z.object({
  user: z.object({
    superuser: z.boolean(),
    spoofingUser: z.boolean().default(false),
    last_known_name: z.string().nullish(),
    last_known_email: z.string(),
    local_user_id: z.string(),
    first_feed_date: z.string().nullish(),
    last_known_profile_photo: z.string().nullish(),
    stripe_connect_id: z.string().nullish(),
    canAdvancedFeedCustomization: z.boolean().transform((arg) => !!arg),
  }),
});

/**
 *
 * @returns
 */
export const fetchLocalUser = async () => {
  const dispatch = useDispatch();
  const auth0User = await auth.getUser();

  const response = await apiClient.post("/local-user", { auth0User });

  if (!response.ok) throw new Error("Request failed");
  const data = await response.json();
  dispatch({ type: "TRACK_USER_SIGNUP", user: data.user });
  store.dispatch({ type: "SAVE_LOCAL_USER", user: data.user }); // TODO: Remove this after refactoring and removing redux
  return responseValidator.parse(data).user;
};

/**
 *
 * @returns
 */
export const useLocalUserQuery = () => {
  return useQuery(["localUser"], fetchLocalUser, {
    staleTime: Infinity,
  });
};
