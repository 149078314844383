import apiCall, { makeUrl } from "../../utils/apiCall";
import { put, spawn } from "redux-saga/effects";
import { history } from "@helloaudio/router";
import { getFeedEpisodes } from "./EpisodeSaga";
import querystring from "querystring";

export function* saveEmailIntegration({ platform, tokens }) {
  const saveEmailIntegration = yield apiCall();
  yield saveEmailIntegration.post(makeUrl("/save-email-integration"), {
    tokens,
    platform,
  });
  yield getEmailIntegrations();
}

export function* getEmailIntegrations() {
  yield put({ type: "SET_LOADING", key: "email-integrations", loading: true });
  const postIntegrationsCall = yield apiCall();
  const result = yield postIntegrationsCall.get(makeUrl("/get-email-integrations"));
  yield put({ type: "SAVE_EMAIL_INTEGRATIONS", integrations: result.data.integrations });
  yield put({ type: "SET_LOADING", key: "email-integrations", loading: false });
}

export function* getAudioInboxIntegrations() {
  yield put({
    type: "SET_LOADING",
    key: "load_audio_inbox_integrations",
    loading: true,
  });
  const getIntegrationsCall = yield apiCall();
  const result = yield getIntegrationsCall.get(makeUrl("/get-audio-inbox-integrations"));
  yield put({
    type: "SAVE_AUDIO_INBOX_INTEGRATIONS",
    audioInboxIntegrations: result.data.integrations,
  });
  yield put({
    type: "SET_LOADING",
    key: "load_audio_inbox_integrations",
    loading: false,
  });
  // running `getAudioInboxEpisodes` for each integration account connected.
  const idList = [];
  result.data.integrations.map((integration) => {
    idList.push(integration.user_integration_token_id);
    return true;
  });
  for (const id of idList) {
    yield spawn(getAudioInboxEpisodes, { userIntegrationTokenId: id });
  }
}

export function* deleteIntegration({ integrationId, platform, callback }) {
  yield put({
    type: "SET_LOADING",
    key: "load_audio_inbox_integrations",
    loading: true,
  });
  yield put({ type: "SET_LOADING", key: "delete-integration", loading: true });
  const deleteIntegrationCall = yield apiCall();
  yield deleteIntegrationCall.post(makeUrl("/delete-integration"), {
    userIntegrationTokenId: integrationId,
    platform,
  });
  yield put({ type: "SET_LOADING", key: "delete-integration", loading: false });
  yield getEmailIntegrations();
  yield getAudioInboxIntegrations();
  if (callback) {
    callback();
  }
}

export function* cancelAudioInboxImport({ platformEpisodeId, destinationFeedId }) {
  yield put({
    type: "OPTIMISTIC_DELETE_EXTERNAL_EPISODE",
    platformEpisodeId,
    destinationFeedId,
  });
  const cancelImportCall = yield apiCall();
  yield cancelImportCall.post(makeUrl("/cancel-audio-inbox-import"), {
    platformEpisodeId,
    destinationFeedId,
  });

  yield getExternalEpisodeList({ silent: true });
}

export function* getAudioInboxEpisodes({
  userIntegrationTokenId,
  loadMore = false,
  searchFilter = false,
  dateSort = "desc",
  nameSort = false,
}) {
  const loadKey = loadMore ? "load_more_" : "load_";
  yield put({
    type: "SET_LOADING",
    key: loadKey + userIntegrationTokenId,
    loading: true,
  });
  const postZoomCall = yield apiCall();
  const result = yield postZoomCall.post(makeUrl("/get-files-for-integration"), {
    userIntegrationTokenId,
    loadMore,
    searchFilter,
    dateSort,
    nameSort,
  });
  if (result.data.error) {
    yield put({
      type: "SAVE_AUDIO_INBOX_ERROR",
      integrationId: userIntegrationTokenId,
      error: result.data.error,
      status: result.data.statusCode,
    });
  } else {
    yield put({
      type: "SAVE_AUDIO_INBOX_RECORDINGS",
      integrationId: userIntegrationTokenId,
      recordings: result.data.recordings,
      loadMore,
      hasMore: result.data.loadMore,
    });
  }
  yield put({
    type: "SET_LOADING",
    key: loadKey + userIntegrationTokenId,
    loading: false,
  });
}

export function* setAudioInboxEpisodeLoading({ userIntegrationTokenId }) {
  const loadKey = "load_";
  yield put({
    type: "SET_LOADING",
    key: loadKey + userIntegrationTokenId,
    loading: false,
  });
}

export function* importAudioInboxRecordings({ importList, destinationFeed, callback }) {
  const mockResults = importList.map((row) => {
    return {
      platform_episode_id: row.id,
      platform: row.platform,
      user_integration_token_id: row.user_integration_token_id,
      status: "scheduled",
      episode_name: row.name,
      destination_feed_id: destinationFeed,
    };
  });
  yield put({
    type: "OPTIMISTIC_SAVE_EXTERNAL_EPISODES",
    externalEpisodes: mockResults,
  });
  const postZoomCall = yield apiCall();
  yield postZoomCall.post(makeUrl("/import-audio-inbox-files"), {
    importList,
    destinationFeed,
  });
  if (callback) {
    callback();
  }
}

export function* importURLs({ urlList, feedId, callback }) {
  yield put({ type: "SET_LOADING", key: "get-feed-episodes", loading: true });
  const postZoomCall = yield apiCall();
  const result = yield postZoomCall.post(makeUrl("/import-from-url"), {
    urlList,
    feedId,
  });
  const data = result.data;
  if (data.successCount > 0) {
    let msg = `${data.successCount} link(s) added to import queue. `;
    if (data.errorCount) {
      msg += `${data.errorCount} link(s) were unable to be imported.`;
    }
    yield put({ type: "SET_SUCCESS", success: msg });
  } else {
    yield put({
      type: "SET_ERROR",
      error: "An error occurred while attempting to import your links. Please try again.",
    });
  }
  yield getFeedEpisodes({ feedId, silent: false });
  if (callback) {
    callback();
  }
}

export function* addFilesToImportQueue({ files, feedId }) {
  // handled by standard lambda
  yield put({ type: "SET_LOADING", key: "get-feed-episodes", loading: true });
  const postZoomCall = yield apiCall();
  yield postZoomCall.post(makeUrl("/add-files-to-import-queue"), {
    files,
    feedId,
  });
  yield getFeedEpisodes({ feedId, silent: false });
}

export function* acceptZoomOauthResult({ code, redirectURI, callback }) {
  const key = "zoom_provider_auth";
  yield put({ type: "SET_LOADING", key, loading: true });
  const postZoomCall = yield apiCall();
  const result = yield postZoomCall.post(makeUrl("/authenticate-zoom"), {
    code,
    redirectURI,
  });
  if (result.data?.uitid) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("uitid", result.data?.uitid);
    window.location.search = urlParams;
  }
  yield put({ type: "SET_LOADING", key, loading: false });
  yield getAudioInboxIntegrations();
}

export function* getExternalEpisodeList({ status, silent = false }) {
  const key = "load_external_episode_list";
  if (!silent) {
    yield put({ type: "SET_LOADING", key, loading: true });
  }
  const postZoomCall = yield apiCall();
  const result = yield postZoomCall.post(makeUrl("/get-external-episodes"), {
    status,
  });
  if (!silent) {
    yield put({ type: "SET_LOADING", key, loading: false });
  }
  yield put({
    type: "SAVE_EXTERNAL_EPISODES",
    externalEpisodes: result.data.externalEpisodes,
  });
}

export function* updateNickname({ nickname, userIntegrationTokenId }) {
  yield put({ type: "SET_LOADING", key: "update-nickname", loading: true });
  yield put({
    type: "SET_NICKNAME_OPTIMISTIC",
    userIntegrationTokenId,
    nickname,
  });
  const postNickname = yield apiCall();
  yield postNickname.post(makeUrl("/update-integration-nickname"), {
    nickname,
    userIntegrationTokenId,
  });
  yield put({ type: "SET_LOADING", key: "update-nickname", loading: false });
}

export function* getMySuperlisteners({ silent, ...params }) {
  if (!silent) {
    yield put({
      type: "SET_LOADING",
      key: "get-my-superlisteners",
      loading: true,
    });
  }
  const getListenersCall = yield apiCall(params);
  const result = yield getListenersCall.get(makeUrl("/superlisteners?"));
  const listeners = result.data.listeners;
  const total = result.data.total;
  if (!silent) {
    yield put({
      type: "SET_LOADING",
      key: "get-my-superlisteners",
      loading: false,
    });
  }
  yield put({ type: "SAVE_MY_SUPERLISTENERS", listeners, total });
}

export function* getSuperlistener({ email, silent }) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-superlistener", loading: true });
  }
  const getSuperlistenerCall = yield apiCall();
  const result = yield getSuperlistenerCall.get(
    makeUrl("/superlistener?") + querystring.encode({ email }),
  );
  yield put({
    type: "SAVE_SUPERLISTENER",
    superlistener: result.data.superlistener,
  });
  if (!silent) {
    yield put({
      type: "SET_LOADING",
      key: "get-superlistener",
      loading: false,
    });
  }
}

export function* getTagSuggestions() {
  const getTagSuggestionsCall = yield apiCall();
  const result = yield getTagSuggestionsCall.get(
    makeUrl("/tag-suggestions?") + querystring.encode({}),
  );
  yield put({
    type: "SAVE_TAG_SUGGESTIONS",
    tagSuggestions: result.data.tagSuggestions,
  });
}

export function* updateSuperlistener({
  email,
  newEmail,
  fname,
  lname,
  fullname,
  phone,
  tags,
  overwriteExisting,
  callback,
}) {
  yield put({ type: "SET_LOADING", key: "save-superlistener", loading: true });
  const postSuperlistenerCall = yield apiCall();
  const result = yield postSuperlistenerCall.post(makeUrl("/superlistener"), {
    email,
    newEmail,
    fname,
    lname,
    fullname,
    phone,
    tags,
    overwriteExisting,
  });

  yield put({ type: "SET_LOADING", key: "save-superlistener", loading: false });

  if (result.data?.emailExists && callback) {
    yield callback(null, { emailExists: true });
  } else {
    if (callback) {
      yield callback(null, { emailExists: false });
    }
    yield history.push("/listeners");
  }
}

export function* uploadListenerCSV({
  addNew,
  callback,
  content,
  csv,
  feedId,
  fetchParams,
  sendEmail,
  silent,
}) {
  yield put({
    type: "SET_LOADING",
    key: "upload-superlistener",
    loading: true,
  });

  const postSuperlistenerUploadCall = yield apiCall();
  const result = yield postSuperlistenerUploadCall.post(makeUrl("/superlistener/upload"), {
    addNew,
    content,
    csv,
    feedId,
    sendEmail,
  });

  yield put({
    type: "SET_LOADING",
    key: "upload-superlistener",
    loading: false,
  });
  if (result.data.error) {
    yield put({
      type: "SET_ERROR",
      error: "An error occurred while attempting to import your links. Please try again.",
    });
  } else {
    yield put({
      type: "SET_SUCCESS",
      success: "Importing listeners, you should receive an email shortly.",
    });
  }

  yield put({ type: "GET_MY_SUPERLISTENERS", params: fetchParams, silent });
  callback();
}

export function* remasterAudioFile({ audioFileId, callback }) {
  yield put({
    type: "SET_LOADING",
    key: "remaster-in-progress",
    loading: true,
  });
  const postRemasterCall = yield apiCall();
  yield postRemasterCall.post(makeUrl("/audio-file/remaster"), {
    audioFileId,
  });

  yield put({
    type: "SET_LOADING",
    key: "remaster-in-progress",
    loading: false,
  });
  callback();
}

export function* trimFileExtensions({ episodes, feedId, callback }) {
  yield put({ type: "SET_LOADING", key: "get-feed-episodes", loading: true });
  const trimFileExtensionsCall = yield apiCall();
  yield trimFileExtensionsCall.post(makeUrl("/trim-file-extensions"), {
    episodes,
  });
  yield getFeedEpisodes({ feedId });
  callback();
}
