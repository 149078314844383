import { FunctionComponent, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  TextInput,
  CardRadioGroup,
  InlineRadioGroup,
  Checkbox,
  Toggle,
  TextArea,
  NativeSelect,
  Select,
  NumberInput,
  DateInput,
} from "@helloaudio/ui";

const TestPage: FunctionComponent = () => {
  const { control, register, formState, setValue, watch } = useForm({
    defaultValues: {
      isPublic: true,
      title: "",
      internalTitle: "",
      author: "",
      website: "",
      feedType: "",
      feedAccessExpirationType: "",

      checkbox: false,
      toggle: false,
      textarea: "",
      nativeSelect: "",
      select: "",
      numberInput: 0,
      date: new Date(),
    },
  });

  const watchAll = watch();
  console.log(watchAll);

  const isPublic = watch("isPublic");
  useEffect(() => {
    const { unsubscribe } = watch(({ isPublic }, { name }) => {
      if (name === "isPublic" && isPublic) setValue("feedType", "date");
    });
    return unsubscribe;
  });

  return (
    <div className="tw-bg-white tw-rounded tw-shadow-sm tw-p-4">
      <div className="tw-container tw-mx-auto">
        {/* isPublic */}
        <Controller
          control={control}
          name="isPublic"
          render={({ field }) => (
            <CardRadioGroup
              className="tw-mb-4"
              value={field.value}
              onChange={field.onChange}
              columns={2}
              label="What kind of feed are we making today?"
              options={[
                {
                  key: "private",
                  value: false,
                  render: {
                    label: "Private",
                    description:
                      "Private feeds are not searchable in podcast directories such as Apple Podcasts and Spotify. They are for privately delivering content to listeners. They may beinstant, date-based, or drip.",
                    info: "You are currently using 5 of 999 private feeds.",
                  },
                },
                {
                  key: "public",
                  value: true,
                  render: {
                    label: "Public",
                    description:
                      "Public feeds can be found in podcast directories such as Apple Podcasts and Spotify. They are for general consumption by the public and not gated listener. Public feeds may only be date-based.",
                    info: "You are currently using 0 of 2 public feeds.",
                  },
                },
              ]}
            />
          )}
        />

        {/* feedType */}
        <Controller
          control={control}
          name="feedType"
          render={({ field }) => (
            <CardRadioGroup
              className="tw-mb-4"
              label="Type of Feed"
              value={field.value}
              onChange={field.onChange}
              columns={3}
              options={[
                {
                  key: "instant",
                  value: "instant",
                  disabled: isPublic,
                  render: {
                    label: "Instant",
                    description:
                      "Ordered episodes that a listener should consume one after the other. No dates are associated with episodes: no scheduling, back-dating, or dripped content. This is a bingeable playlist of content.",
                  },
                },
                {
                  key: "date",
                  value: "date",
                  render: {
                    label: "Date-Based",
                    description:
                      "Episodes each have a calendar date associated with them. Content can be scheduled for future release, published immediately, or back-dated.",
                  },
                },
                {
                  key: "drip",
                  value: "drip",
                  disabled: isPublic,
                  render: {
                    label: "Drip",
                    description:
                      "Episodes are released on a time-based delay relative to a listener's start date.",
                  },
                },
              ]}
            />
          )}
        />

        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DateInput
              className="tw-mb-4"
              label="Publish date"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="select"
          render={({ field }) => (
            <Select
              className="tw-mb-4"
              label="Welcome episode"
              placeholder="Choose welcome episode"
              value={field.value}
              onChange={field.onChange}
              options={[
                {
                  key: "0",
                  label: "Welcome Episode",
                  value: "0",
                },
                {
                  key: "1",
                  label: "Episode 1",
                  value: "1",
                },
                {
                  key: "2",
                  label: "Episode 2",
                  value: "2",
                },
                {
                  key: "3",
                  label: "Episode 3",
                  value: "3",
                },
                {
                  key: "4",
                  label: "Episode 4",
                  value: "4",
                },
              ]}
            />
          )}
        />

        <NativeSelect
          {...register("nativeSelect")}
          className="tw-mb-4"
          label="Frequency"
          options={[
            {
              key: "0",
              label: "Weekly",
              value: "0",
            },
            {
              key: "1",
              label: "Monthly",
              value: "1",
            },
            {
              key: "2",
              label: "Annually",
              value: "2",
            },
          ]}
        />

        <Controller
          control={control}
          name="toggle"
          render={({ field }) => (
            <Toggle
              className="tw-mb-4"
              label="Custom feed color"
              description="(Applied to subscribe page)"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <Checkbox
          {...register("checkbox")}
          className="tw-mb-4"
          label={`Restrict Access to Past Episodes ("Membership Style")`}
          description="Listener only receives episodes published after their start date"
        />

        <TextArea
          {...register("textarea")}
          className="tw-mb-4"
          rows={4}
          label="Description"
          placeholder="A sentence or two describing this feed's content"
          error={formState.errors.textarea?.message}
        />

        {/* title */}
        <TextInput
          {...register("title")}
          className="tw-mb-4"
          label="Title"
          error={formState.errors.title?.message}
        />

        {/* internalTitle */}
        <TextInput
          {...register("internalTitle")}
          className="tw-mb-4"
          label="Internal Title"
          error={formState.errors.internalTitle?.message}
        />

        {/* author */}
        <TextInput
          {...register("author")}
          className="tw-mb-4"
          label="Author"
          placeholder="e.g. John Doe, Acme, Inc."
          error={formState.errors.author?.message}
        />

        {/* website */}
        <TextInput
          {...register("website")}
          className="tw-mb-4"
          label="Website"
          error={formState.errors.website?.message}
        />

        <Controller
          control={control}
          name="numberInput"
          render={({ field }) => (
            <NumberInput
              className="tw-mb-4"
              label="Number of days"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        {/* feedAccessExpirationType */}
        <Controller
          control={control}
          name="feedAccessExpirationType"
          render={({ field }) => (
            <InlineRadioGroup
              label="Feed Access for Listeners Expires:"
              value={field.value}
              onChange={field.onChange}
              options={[
                {
                  key: "never",
                  value: "never",
                  label: "Never",
                },
                {
                  key: "on-specific-date",
                  value: "on-specific-date",
                  label: "On specific date",
                },
                {
                  key: "after-number-of-days",
                  value: "after-number-of-days",
                  label: "Number of days after listener's start date",
                },
              ]}
            />
          )}
        />
      </div>
    </div>
  );
};

export default TestPage;
