import { forwardRef, ComponentProps } from "react";

const RefreshIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.698 15.32A5.36 5.36 0 0 0 15 20.74v1.458l-.59 2.68a9.491 9.491 0 0 1-8.785-9.557 9.346 9.346 0 0 1 7.5-9.335h1.25l.03 3.952a5.375 5.375 0 0 0-4.707 5.383Z"
      fill="#FB462A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 24.908c-.199 0-.393-.018-.59-.03l.59-2.68V20.74a5.42 5.42 0 0 0 0-10.838c-.198 0-.396.013-.593.036l-.032-1.452s-.41-2.75.625-2.75a9.482 9.482 0 0 1 9.375 9.585A9.482 9.482 0 0 1 15 24.908Z"
      fill="#2E20B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.253 26.61v-8.75l-4.378 4.376 4.378 4.375Z"
      fill="#2E20B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.743 4.11v8.126l3.765-4.059-3.765-4.066Z"
      fill="#FB462A"
    />
  </svg>
));

export default RefreshIcon;
