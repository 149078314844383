import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* resetActivation({ listenerId }) {
  const key = `reset-activation-${listenerId}`;
  yield put({ type: "SET_LOADING", key, loading: true });
  const resetActivationCall = yield apiCall();
  yield resetActivationCall.post(makeUrl("/reset-activation"), {
    listenerId,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({
    type: "SET_SUCCESS",
    success: "Listener has been switched back to being able to access the subscribe page.",
  });
}
