import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import querystring from "querystring";

export function* getStepInfo({ feedId }) {
  const getListenerCountCall = yield apiCall();
  const results = yield getListenerCountCall.get(
    makeUrl("/get-step-info?") + querystring.encode({ feedId }),
  );
  yield put({
    type: "SAVE_STEP_INFO",
    activeEpisode: results.data.activeEpisode,
    publishedEpisode: results.data.publishedEpisode,
  });
}
