import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import { history } from "@helloaudio/router";
import { getSubscription } from "./SubscriptionSaga";
import { getListeners } from "./ListenerSaga";

export function* acceptThinkificOauthResult({ code, state, subdomain }) {
  yield history.push("/my-feeds");
  yield put({
    type: "SET_LOADING",
    key: "accepting-thinkific-result",
    loading: true,
  });
  const postAcceptThinkificCall = yield apiCall();
  const result = yield postAcceptThinkificCall.post(makeUrl("/accept-thinkific-oauth-result"), {
    code,
    state,
    subdomain,
  });
  yield put({
    type: "SAVE_SINGLE_INTEGRATION",
    integration: { thinkific: result.data.thinkific },
  });
  yield put({
    type: "SET_LOADING",
    key: "accepting-thinkific-result",
    loading: false,
  });
  yield getSubscription();
}

export function* getThinkificEnrollments() {
  yield put({
    type: "SET_LOADING",
    key: "get-thinkific-enrollments",
    loading: true,
  });
  const postThinkificEnrollmentsCall = yield apiCall();
  yield postThinkificEnrollmentsCall.post(makeUrl("/thinkific-enrollments"), {});
  yield put({
    type: "SET_LOADING",
    key: "get-thinkific-enrollments",
    loading: false,
  });
}

export function* getThinkificCourses() {
  yield put({ type: "SAVE_THINKIFIC_AUTH_ERROR", value: false });
  yield put({
    type: "SET_LOADING",
    key: "get-thinkific-courses",
    loading: true,
  });
  const postThinkificCoursesCall = yield apiCall();
  const result = yield postThinkificCoursesCall.post(makeUrl("/thinkific-courses"), {});
  if (result.data.reAuthenticate) {
    yield put({ type: "SAVE_THINKIFIC_AUTH_ERROR", value: true });
  } else {
    yield put({
      type: "SAVE_THINKIFIC_COURSES",
      thinkificCourses: result.data.courses,
    });
  }
  yield put({
    type: "SET_LOADING",
    key: "get-thinkific-courses",
    loading: false,
  });
}

export function* saveThinkificSync({ feedId, thinkificCourseId, content, sendEmail, callback }) {
  yield put({ type: "SET_LOADING", key: "add-thinkific-sync", loading: true });
  const postThinkificCoursesCall = yield apiCall();
  yield postThinkificCoursesCall.post(makeUrl("/thinkific-sync"), {
    feedId,
    thinkificCourseId,
    sendEmail,
    content,
  });
  yield put({ type: "SET_LOADING", key: "add-thinkific-sync", loading: false });
  yield callback();
  yield getListeners({ feedId });
}
