import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* updateProfile({
  name,
  email,
  password,
  replyTo,
  business,
  copyright,
  affiliateLink,
  affiliateLinkOptions,
  silent,
}) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "update-profile", loading: true });
  }
  const patchProfileCall = yield apiCall();
  const result = yield patchProfileCall.patch(makeUrl("/local-user"), {
    name,
    email,
    password,
    replyTo,
    business,
    copyright,
    affiliateLink,
    affiliateLinkOptions,
  });
  if (!silent) {
    yield put({ type: "SAVE_LOCAL_USER", user: result.data.user });
  }
  yield put({ type: "SET_LOADING", key: "update-profile", loading: false });
  yield put({ type: "SET_SAVED", key: "profile" });
  yield put({
    type: "SET_SUCCESS",
    success: "Your account information has been updated.",
  });
}

export function* loadAffiliateCodeFromFirstPromoter() {
  yield put({
    type: "SET_LOADING",
    key: "load-affiliate-code-from-first-promoter",
    loading: true,
  });
  const getAffiliateCodeCall = yield apiCall();
  const result = yield getAffiliateCodeCall.get(
    makeUrl("/local-user/affiliate-code?") + querystring.encode({}),
  );
  yield put({
    type: "SAVE_AFFILIATE_CODE",
    affiliateCode: result.data.affiliateCode,
  });
  yield put({
    type: "SET_LOADING",
    key: "load-affiliate-code-from-first-promoter",
    loading: false,
  });
}
