/**
 *
 */
export enum ColorScheme {
  Light = "light",
  Dark = "dark",
  System = "system",
}

/**
 *
 */
const LOCAL_STORAGE_KEY = "prefersColorScheme";
const DARK_MODE_CLASS = "tw-dark";

/**
 *
 * @returns
 */
export const getPreferredColorScheme = (): ColorScheme => {
  const colorScheme = localStorage.getItem(LOCAL_STORAGE_KEY) as ColorScheme;
  return colorScheme || ColorScheme.System;
};

/**
 *
 * @param colorScheme
 */
export const setPreferredColorScheme = (colorScheme: ColorScheme) => {
  switch (colorScheme) {
    case ColorScheme.Light:
      localStorage.setItem(LOCAL_STORAGE_KEY, ColorScheme.Light);
      document.documentElement.classList.remove(DARK_MODE_CLASS);
      break;

    case ColorScheme.Dark:
      localStorage.setItem(LOCAL_STORAGE_KEY, ColorScheme.Dark);
      document.documentElement.classList.add(DARK_MODE_CLASS);
      break;

    case ColorScheme.System:
    default:
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        document.documentElement.classList.add(DARK_MODE_CLASS);
      } else {
        document.documentElement.classList.remove(DARK_MODE_CLASS);
      }
  }
};
