import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import reducer from "./reducers";
import mySaga from "./sagas";

const createRootReducer = () =>
  combineReducers({
    basic: reducer,
  });

const sagaMiddleware = createSagaMiddleware();
const appMiddleware = [sagaMiddleware];

// Optionally load redux-logger for better debugging on dev
if (process.env.REDUX_LOGGER_ENABLED) {
  appMiddleware.push(logger);
}

const store = createStore(
  createRootReducer(),
  composeWithDevTools(applyMiddleware(...appMiddleware)),
);

sagaMiddleware.run(mySaga);

export default store;
