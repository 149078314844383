import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";

import { Label, NonBreakingSpace } from "@helloaudio/ui";

interface SelectProps<T> {
  className?: string;
  label?: string;
  labelClassName?: string;
  error?: string;
  name?: string;
  placeholder?: string;
  value: T;
  disabled?: boolean;
  checkIcon?: boolean;
  onBlur?: (value: T) => void;
  onChange: (value: T) => void;
  options: { key?: string; label: string; value: T; disabled?: boolean }[];
  optionsClassName?: string;
}

function Select<T>(props: SelectProps<T>) {
  const {
    className,
    value,
    onChange,
    label,
    labelClassName,
    error,
    disabled,
    options,
    optionsClassName,
    placeholder,
    checkIcon,
  } = props;

  return (
    <Listbox as="div" disabled={disabled} className={className} value={value} onChange={onChange}>
      {({ open, disabled }) => (
        <>
          {label && (
            <Listbox.Label as={Label} className={clsx("tw-mb-2", labelClassName)}>
              {label}
            </Listbox.Label>
          )}

          <div className="tw-relative">
            <Listbox.Button
              className={clsx(
                "tw-transition-colors tw-duration-100 tw-relative tw-w-full tw-border-solid tw-border tw-rounded tw-pl-3 tw-pr-10 tw-py-2 tw-text-left tw-cursor-default focus:tw-outline-none focus:tw-ring-0 sm:tw-text-sm",
                !error && "focus:tw-border-indigo-800 focus:tw-bg-indigo-10",
                error && "focus:tw-border-red-500 focus:tw-bg-red-10",
                !error && (open ? "tw-border-indigo-800" : "tw-border-gray-300"),
                error && (open ? "tw-border-red-500" : "tw-border-red-300"),
                open && !disabled && error && "tw-bg-red-10",
                open && !disabled && !error && "tw-bg-indigo-10",
                !open && !disabled && "tw-bg-white",
                disabled && "tw-bg-gray-100",
              )}
            >
              <span
                className={clsx(
                  "tw-block tw-truncate tw-font-Montserrat",
                  value === undefined || value === "" ? "tw-text-gray-400" : "tw-text-gray-900",
                )}
              >
                {(value === undefined || value === ""
                  ? placeholder
                  : options.find((option) => option.value === value)?.label) || (
                  <NonBreakingSpace />
                )}
              </span>
              <span className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-pointer-events-none">
                {open ? (
                  <ChevronUpIcon className="tw-shrink-0 tw-w-5 tw-h-5 feed-arrowIcon" />
                ) : (
                  <ChevronDownIcon className="tw-shrink-0 tw-w-5 tw-h-5 feed-arrowIcon" />
                )}
              </span>
            </Listbox.Button>

            {error && (
              <div className="tw-absolute tw-inset-y-0 tw-right-5 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none">
                <ExclamationCircleIcon
                  className="tw-h-5 tw-w-5 tw-text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}

            <Listbox.Options
              className={clsx(
                "tw-list-none tw-m-0 tw-mt-1 tw-p-0 tw-absolute tw-z-dropdown tw-w-full tw-bg-white tw-border-solid tw-border tw-rounded tw-border-gray-300 tw-max-h-60 tw-py-1 tw-text-base tw-shadow-lg tw-overflow-auto focus:tw-outline-none sm:tw-text-sm",
                optionsClassName,
              )}
            >
              {options.map((option, index) => (
                <Listbox.Option
                  key={option.key !== undefined ? option.key : index}
                  className={({ active }) =>
                    clsx(
                      value === option.value && "tw-bg-yellow-100",
                      active && "tw-bg-yellow-100",
                      checkIcon && "tw-pl-8",
                      "tw-cursor-pointer tw-text-gray-900 tw-cursor-default tw-select-none tw-relative tw-py-2 tw-pl-4 tw-pr-4",
                    )
                  }
                  value={option.value}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={clsx(
                          selected ? "tw-font-semibold" : "tw-font-normal",
                          "tw-block tw-truncate tw-font-Montserrat",
                        )}
                      >
                        {option.label}
                      </span>

                      {selected && checkIcon && (
                        <span
                          className={clsx(
                            "tw-text-indigo-800 tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-1.5",
                          )}
                        >
                          <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
          {error && <p className="tw-mt-1 tw-mb-0 tw-text-sm tw-italic tw-text-red-600">{error}</p>}
        </>
      )}
    </Listbox>
  );
}

export default Select;
