import { FunctionComponent, HTMLAttributes } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import generateInitials from "./generateInitials";

interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  imageUrl?: string;
  imageAltText?: string;
}
const Avatar: FunctionComponent<AvatarProps> = ({
  className,
  name,
  imageUrl,
  imageAltText,
  ...htmlDivAttributes
}) => {
  const initials = generateInitials(name);

  return (
    <div
      {...htmlDivAttributes}
      className={clsx(
        className,
        "tw-w-8 tw-h-8 tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-white tw-border-solid tw-bg-indigo-800",
      )}
    >
      {imageUrl && (
        <img
          className="tw-inline-block tw-h-full tw-w-full tw-rounded-full"
          src={imageUrl}
          alt={imageAltText}
        />
      )}

      {!imageUrl && <span className="tw-text-white tw-text-sm tw-font-semibold">{initials}</span>}
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  imageAltText: PropTypes.string,
  className: PropTypes.string,
};

export default Avatar;
