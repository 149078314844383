import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { PuffLoader } from "react-spinners";

import { Guarded } from "@helloaudio/containers";

const { loginGuard } = Guarded;

function Loading() {
  return (
    <div className="loading-container">
      <h3 style={{ textAlign: "center" }}>
        <PuffLoader color="#32325d" size={150} aria-label="Loading Spinner" data-testid="loader" />
        Loading
      </h3>
    </div>
  );
}

const PrivateShellCheckoutLayout = () => {
  return (
    <Guarded by={[loginGuard]}>
      <Suspense fallback={<Loading />}>
        <main
          id="ha_main"
          className="tw-flex tw-max-w-[1100px] tw-mx-auto tw-min-h-screen tw-pt-[30px] tw-px-[40px]"
        >
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </main>
      </Suspense>
    </Guarded>
  );
};

export default PrivateShellCheckoutLayout;
