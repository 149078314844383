import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* checkPromoCode({ promoCode }) {
  const checkPromoCodeCall = yield apiCall();
  const result = yield checkPromoCodeCall.post(makeUrl("/subscription/check-promo-code"), {
    promoCode,
  });
  if (result.data.promoCode) {
    yield put({ type: "SAVE_PROMO_CODE_VALID", valid: true });
    // yield put({ type: 'SET_SUCCESS', success: `Coupon ${promoCode} is valid! You get ${result.data.promoCode.coupon.percent_off}% off for ${result.data.promoCode.coupon.duration_in_months ? result.data.promoCode.coupon.duration_in_months + ' months' : result.data.promoCode.coupon.duration}!` });
    yield put({
      type: "SET_SUCCESS",
      success: `Coupon ${promoCode} is valid!`,
    });
  } else {
    yield put({ type: "SAVE_PROMO_CODE_VALID", valid: false });
  }
}
