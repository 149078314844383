export function* trackUserSignup({ user, silent }) {
  yield;
  profitwell("start", { user_email: user.last_known_email });
  try {
    $FPROM.trackSignup(
      {
        email: user.last_known_email,
      },
      function () {},
    );
  } catch (e) {}
}
