import { forwardRef, ComponentProps } from "react";

const HeadphonesIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 13.303v1.867h1.875v-1.867H6.25Zm15.625 0v1.867h1.875v-1.867h-1.875Zm-15 1.867h1.25v9.959h-1.25a3.132 3.132 0 0 1-2.21-.912 3.105 3.105 0 0 1-.915-2.2v-3.735c0-.825.33-1.617.915-2.2a3.132 3.132 0 0 1 2.21-.912Zm15 0h1.25c.829 0 1.624.328 2.21.912.586.583.915 1.375.915 2.2v3.735c0 .825-.33 1.617-.915 2.2a3.132 3.132 0 0 1-2.21.912h-1.25V15.17Z"
      fill="#2E20B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.368 13.303C21.595 9.455 18.597 6.581 15 6.581c-3.597 0-6.595 2.874-7.368 6.722H6.269C7.14 8.681 10.709 5.212 15 5.212c4.291 0 7.86 3.468 8.731 8.091h-1.363ZM8.125 15.17H10v9.959H8.125V15.17ZM3.75 17.66h1.875v4.98H3.75v-4.98ZM20 15.17h1.875v9.959H20V15.17Zm4.375 2.49h1.875v4.98h-1.875v-4.98Z"
      fill="#FB462A"
    />
    <path fill="#2E20B1" d="M8 15.17h2v9.959H8zM20 15.17h2v9.959h-2z" />
  </svg>
));

export default HeadphonesIcon;
