import { FunctionComponent, ReactNode, useCallback, useId } from "react";
import { NumericFormat, OnValueChange } from "react-number-format";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

import { Label } from "@helloaudio/ui";
import clsx from "clsx";

interface NumberInputProps {
  className?: string;
  label?: string;
  helpText?: ReactNode;
  error?: string;
  value: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  prefix?: string;
  thousandSeparator?: boolean;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  onChange: (value: number) => void;
}

const NumberInput: FunctionComponent<NumberInputProps> = ({
  className,
  label,
  helpText,
  prefix,
  thousandSeparator,
  decimalScale,
  fixedDecimalScale,
  error,
  value,
  min,
  max,
  disabled,
  onChange,
}) => {
  const id = useId();

  const validate = useCallback(
    (value: number) => {
      if (min !== undefined && min > value) return false;
      if (max !== undefined && max < value) return false;
      return true;
    },
    [min, max],
  );

  const increment = () => {
    if (validate(value + 1)) onChange(value + 1);
  };
  const decrement = () => {
    if (validate(value - 1)) onChange(value - 1);
  };

  const handleChange: OnValueChange = useCallback(
    ({ floatValue }) => {
      let nextValue = floatValue || 0;
      if (min !== undefined && min > nextValue) nextValue = min;
      if (max !== undefined && max < nextValue) nextValue = max;
      onChange(nextValue);
    },
    [validate, min, max],
  );

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={`input${id}`} helpText={helpText} className="tw-mb-2">
          {label}
        </Label>
      )}

      <div className="tw-flex">
        <button
          type="button"
          disabled={disabled}
          className={clsx(
            "-tw-mr-px tw-p-0 tw-w-10 tw-border-solid tw-border tw-boder-gray-300 tw-relative tw-inline-flex tw-items-center tw-border-gray-300 tw-rounded-l tw-bg-gray-50 focus:tw-outline-none focus:tw-border-indigo-800 focus:tw-z-20",
            !disabled && "hover:tw-bg-gray-100",
          )}
          onClick={decrement}
        >
          <MinusIcon className="tw-m-auto tw-h-5 w-5 tw-text-gray-400" aria-hidden="true" />
        </button>
        <div className="tw-relative tw-flex tw-items-stretch tw-flex-grow tw-z-10">
          <NumericFormat
            prefix={prefix}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            onValueChange={handleChange}
            disabled={disabled}
            id={`input${id}`}
            value={value}
            type="text"
            className={clsx(
              "tw-transition-colors tw-duration-100 tw-form-input tw-block tw-w-full tw-border tw-border-solid focus:tw-outline-none focus:tw-ring-0 md:tw-text-sm tw-spin-button-none",
              error &&
                "tw-text-red-900 tw-placeholder-red-300 tw-border-red-300 focus:tw-border-red-500 focus:tw-bg-red-10",
              !error &&
                "tw-placeholder-gray-400 tw-border-gray-300 focus:tw-border-indigo-800 focus:tw-bg-indigo-10",
              disabled && "tw-bg-gray-100",
            )}
            aria-invalid={!!error}
            aria-errormessage={error && `input${id}error`}
          />
          {error && (
            <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none">
              <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true" />
            </div>
          )}
        </div>
        <button
          type="button"
          disabled={disabled}
          className={clsx(
            "-tw-ml-px tw-p-0 tw-w-10 tw-border-solid tw-border tw-boder-gray-300 tw-relative tw-inline-flex tw-items-center tw-border-gray-300 tw-rounded-r tw-bg-gray-50 focus:tw-outline-none focus:tw-border-indigo-800 focus:tw-z-20",
            !disabled && "hover:tw-bg-gray-100",
          )}
          onClick={increment}
        >
          <PlusIcon className="tw-m-auto tw-h-5 w-5 tw-text-gray-400" aria-hidden="true" />
        </button>
      </div>

      {error && (
        <p className="tw-mt-1 tw-mb-0 tw-text-sm tw-italic tw-text-red-600" id={`input${id}error`}>
          {error}
        </p>
      )}
    </div>
  );
};

export default NumberInput;
