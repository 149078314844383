import apiCall, { makeUrl } from "../../utils/apiCall";

export function* setRollUseDolby({ feedId, slot, position, useDolby }) {
  const postUseDolbyCall = yield apiCall();
  yield postUseDolbyCall.put(makeUrl("/feed-roll"), {
    feedId,
    slot,
    position,
    useDolby,
  });
}
