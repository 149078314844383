// @ts-check
import { useSelector } from "react-redux";
import Intercom from "@intercom/messenger-js-sdk";
import moment from "moment";

/**
 * @typedef {{
 *   id: string,
 *   name: string,
 *   email: string,
 *   createdAt: number
 * }} User
 * @typedef {import('../../../InterfaceType/Interface').StateInterface} State
 * @typedef {{
 *   app_id: string,
 *   user_id: string,
 *   user_hash: string,
 *   name: string,
 *   email: string,
 *   created_at?: number
 * }} IntercomConfig
 */

const IntercomMessenger = function IntercomMessenger() {
  const localUser = useSelector(
    /**
     * @param {State} state
     * @returns
     */
    (state) => state.basic.localUser,
  );

  if (localUser?.local_user_id) {
    /** @type {IntercomConfig} */
    const intercomConfig = {
      app_id: "skxrj9oq",
      user_id: localUser.local_user_id,
      user_hash: localUser.intercomUserHash,
      name: localUser.last_known_name,
      email: localUser.last_known_email,
    };
    if (localUser.first_feed_date) {
      intercomConfig.created_at = moment(localUser.first_feed_date).unix();
    }
    Intercom(intercomConfig);
  }

  return <div />;
};

export default IntercomMessenger;
