import { forwardRef, TextareaHTMLAttributes, useId } from "react";
import clsx from "clsx";

import { Label } from "@helloaudio/ui";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  helpText?: string;
  error?: string;
  textAreaClassName?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, helpText, label, error, textAreaClassName, ...htmlTextAreaAttributes }, ref) => {
    const id = useId();

    return (
      <div className={className}>
        {label && (
          <Label
            htmlFor={`textarea${id}`}
            helpText={helpText}
            className="tw-mb-2 tw-text-sm tw-font-Montserrat"
          >
            {label}
          </Label>
        )}

        <textarea
          {...htmlTextAreaAttributes}
          id={`textarea${id}`}
          ref={ref}
          className={clsx(
            textAreaClassName,
            "tw-transition-colors tw-duration-100 tw-form-input tw-block tw-w-full tw-border tw-border-solid tw-rounded focus:tw-outline-none focus:tw-ring-0 tw-text-sm tw-font-Montserrat",
            !error
              ? "tw-placeholder-gray-400 tw-border-gray-300 focus:tw-border-indigo-800 focus:tw-bg-indigo-10"
              : "tw-text-red-900 tw-placeholder-red-300 tw-border-red-300 focus:tw-border-red-500 focus:tw-bg-red-10",
          )}
          aria-invalid={!!error}
          aria-errormessage={error && `textarea${id}error`}
        />

        {error && (
          <p
            className="tw-mt-1 tw-mb-0 tw-text-sm tw-italic tw-text-red-600"
            id={`input${id}error`}
          >
            {error}
          </p>
        )}
      </div>
    );
  },
);

TextArea.displayName = "TextArea";

export default TextArea;
