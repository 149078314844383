import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";

import { useIsMounted } from "@helloaudio/hooks";

import GuardFunction from "./GuardFunction";

import loginGuard from "./guards/loginGuard";
import signupGuard from "./guards/signupGuard";
import guestGuard from "./guards/guestGuard";

interface Guards {
  loginGuard: GuardFunction;
  signupGuard: GuardFunction;
  guestGuard: GuardFunction;
}

interface GuardedProps {
  by: GuardFunction[];
  children?: ReactNode;
}
const Guarded: FunctionComponent<GuardedProps> & Guards = ({ by, children }) => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const [passed, setPassed] = useState(false);

  useEffect(() => {
    const executor = async () => {
      // Execute guards sequentially
      for (const guardFn of by) {
        const pass = await guardFn({ navigate });
        if (!pass) return;
      }

      if (isMounted()) setPassed(true);
    };
    executor();
  }, [by]);

  return passed ? <>{children}</> : null;
};
Guarded.propTypes = {
  by: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  children: PropTypes.node,
};

Guarded.loginGuard = loginGuard;
Guarded.signupGuard = signupGuard;
Guarded.guestGuard = guestGuard;

export default Guarded;
