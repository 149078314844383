import apiCall, { makeUrl } from "../../utils/apiCall";
import { takeLatest, put, call, spawn } from "redux-saga/effects";
export function* getNextInvoice() {
  yield put({
    type: "SET_LOADING",
    key: "get-upcoming-invoices",
    loading: true,
  });
  const getNextInvoice = yield apiCall();
  const result = yield getNextInvoice.get(makeUrl("/subscription/next-invoice"));
  if (result.status === 200) {
    yield put({ type: "SAVE_INVOICE", nextInvoice: result.data });
  }
  yield put({
    type: "SET_LOADING",
    key: "get-upcoming-invoices",
    loading: false,
  });
}

export function* getInvoices() {
  yield put({ type: "SET_LOADING", key: "get-invoices", loading: true });
  const getInvoicesCall = yield apiCall();
  const result = yield getInvoicesCall.get(makeUrl("/subscription/last-invoice"));
  yield put({ type: "SAVE_INVOICES", invoices: result.data.invoices });
  yield put({ type: "SET_LOADING", key: "get-invoices", loading: false });
}
