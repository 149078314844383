import { Fragment, FunctionComponent } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HistoryRouter } from "@helloaudio/router"; // TODO: remove after migrating to NEXT
import { Intercom, Toaster } from "@helloaudio/ui";
import { Guarded } from "@helloaudio/containers";
import {
  LazyHomepage,
  LazyMyFeeds,
  LazySuperlisteners,
  LazyActions,
  LazyAffiliate,
  LazyIntegrations,
  LazyAudioInbox,
  LazyCreateFeed,
  LazyFeedType,
  LazyAccount,
  LazyAdminTools,
  LazyFeed,
  LazyBillingInfo,
  LazyEpisode,
  LazyDescriptSelectFeed,
  LazyAudioInboxPlatform,
  LazySuperlistener,
  LazyManageFeedSignup,
  LazyFeedSignup,
  LazyFeedSignupBillingDashboardLogin,
  LazyCheckout,
  LazySubscription,
  LazyExternalStats,
} from "@helloaudio/pages";
import { PrivateShell, PrivateShellCheckoutLayout } from "@helloaudio/shells";
import { usePageView } from "./utils/tracking";

// TODO: Remove after refactoring
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import "./App.scss"; // TODO: Remove after refactoring
import "./App.css";

import TestPage from "./components/pages/TestPage";

const { guestGuard, loginGuard, signupGuard } = Guarded;

const App: FunctionComponent = () => {
  return (
    <Fragment>
      <Toaster />
      <HistoryRouter>
        <PageViewTracker />
        <Intercom />
        <Routes>
          {/* TODO: Would be nice to place under `/auth/*` and add `/auth/callback` route */}
          <Route path="/listener-billing" element={<LazyFeedSignupBillingDashboardLogin />} />
          <Route path="/login" element={<Guarded by={[guestGuard, loginGuard]} />} />
          <Route path="/signup" element={<Guarded by={[guestGuard, signupGuard]} />} />
          <Route path="/feed/:feedId/signup" element={<LazyFeedSignup />} />

          <Route element={<PrivateShellCheckoutLayout />}>
            <Route path="subscription" element={<LazySubscription />} />
            <Route path="checkout" element={<LazyCheckout />} />
          </Route>

          <Route element={<PrivateShell />}>
            <Route path="test" element={<TestPage />} />
            <Route index element={<LazyHomepage />} />
            <Route path="my-feeds" element={<LazyMyFeeds />} />
            <Route path="listeners" element={<LazySuperlisteners />} />
            <Route path="listener/:email" element={<LazySuperlistener />} />
            <Route path="actions" element={<LazyActions />} />
            <Route path="affiliate" element={<LazyAffiliate />} />
            <Route path="integrations" element={<LazyIntegrations />} />
            <Route path="integrations/:platform" element={<LazyIntegrations />} />
            <Route path="integrations/:platform/:status" element={<LazyIntegrations />} />
            <Route path="audio-inbox" element={<LazyAudioInbox />} />
            <Route path="audio-inbox/:platform" element={<LazyAudioInboxPlatform />} />
            <Route path="audio-inbox/:platform/:status" element={<LazyAudioInboxPlatform />} />
            <Route path="create-feed" element={<LazyFeedType />} />
            <Route path="create-feed/:feedType" element={<LazyCreateFeed />} />
            <Route path="create-feed/:feedType/:id" element={<LazyCreateFeed />} />
            <Route path="account" element={<LazyAccount />} />
            <Route path="admin-tools" element={<LazyAdminTools />} />
            <Route path="external-stats" element={<LazyExternalStats />} />

            <Route path="feed/:id" element={<LazyFeed />} />
            <Route path="feed/:id/:activeTab" element={<LazyFeed />} />
            <Route path="feed/:id/manage-signup" element={<LazyManageFeedSignup />} />

            <Route path="plans" element={<LazyBillingInfo />} />
            <Route path="billing" element={<Navigate to="/plans" replace />} />

            <Route path="episode/:id" element={<LazyEpisode />} />
            <Route path="descript-select-feed" element={<LazyDescriptSelectFeed />} />
          </Route>
          <Route
            path="/integrations/start-thinkific"
            element={
              <Guarded
                by={[
                  // TODO: Think of different approach???
                  () => {
                    window.location.href = `${process.env.API_URL}/install-thinkific${window.location.search}`;
                    return false;
                  },
                ]}
              />
            }
          />
          {/* TODO: Do we need 404 page??? */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </HistoryRouter>
    </Fragment>
  );
};

const PageViewTracker = () => {
  usePageView();
  return null;
};

export default App;
