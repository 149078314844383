import { forwardRef, Fragment, LabelHTMLAttributes, ReactNode } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Tooltip } from "@helloaudio/ui";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  children?: ReactNode;
  className?: string;
  helpText?: ReactNode;
  helpTextColor?: string;
  required?: boolean;
  weight?:
    | "thin"
    | "extralight"
    | "light"
    | "normal"
    | "medium"
    | "semibold"
    | "bold"
    | "extrabold"
    | "black";
}
const Label = forwardRef<HTMLLabelElement, LabelProps>(
  (
    { children, className, helpText, helpTextColor, required, weight, ...htmlLabelAttributes },
    ref,
  ) => {
    weight = weight || "semibold";
    return (
      <label
        ref={ref}
        {...htmlLabelAttributes}
        className={clsx(
          "tw-text-base tw-text-black tw-font-Montserrat",
          `tw-font-${weight}`,
          className,
        )}
      >
        {children && (
          <Fragment>
            {children}
            {required && <sup className="tw-text-red-600 tw-text-base">*</sup>}
            {helpText && (
              <Tooltip label={helpText} helpTextColor={helpTextColor}>
                <QuestionMarkCircleIcon className="tw-ml-1 tw-text-black tw-w-5 tw-h-5 tw-align-bottom" />
              </Tooltip>
            )}
          </Fragment>
        )}
      </label>
    );
  },
);

Label.displayName = "Label";
Label.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  helpText: PropTypes.any,
  helpTextColor: PropTypes.string,
  required: PropTypes.bool,
  weight: PropTypes.oneOf([
    "thin",
    "extralight",
    "light",
    "normal",
    "medium",
    "semibold",
    "bold",
    "extrabold",
    "black",
  ]),
};

export default Label;
