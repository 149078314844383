import { FunctionComponent, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { PuffLoader } from "react-spinners";

import { Guarded, Header, Sidebar, Topbar } from "@helloaudio/containers";

const { loginGuard } = Guarded;

function Loading() {
  return (
    <div className="loading-container">
      <h3 style={{ textAlign: "center" }}>
        <PuffLoader color="#32325d" size={150} aria-label="Loading Spinner" data-testid="loader" />
        Loading
      </h3>
    </div>
  );
}

const PrivateShell: FunctionComponent = () => {
  return (
    <Guarded by={[loginGuard]}>
      {/* <Suspense fallback="loading..."> */}
      <Suspense fallback={<Loading />}>
        <Topbar /> {/* Used to render navigation on smaller screens */}
        <Sidebar /> {/* Used to render navigation on larger screens */}
        <Header />
        <main id="ha_main" className="tw-pt-32 tw-px-4 tw-pb-4 tw-relative md:tw-ml-16 md:tw-pt-16">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </main>
      </Suspense>
    </Guarded>
  );
};

export default PrivateShell;
