import apiCall, { makeUrl } from "../../utils/apiCall";
import { getAllUsers } from "./UserSaga";

export function* setOverride({ localUserId, limit }) {
  const updateLimitCall = yield apiCall();
  yield updateLimitCall.post(makeUrl("/admin/override"), {
    localUserId,
    limit,
  });
  yield getAllUsers();
}

export function* setPublicOverride({ localUserId, limit }) {
  const updateLimitCall = yield apiCall();
  yield updateLimitCall.post(makeUrl("/admin/public-override"), {
    localUserId,
    limit,
  });
  yield getAllUsers();
}

export function* setRemasterOverride({ localUserId, count }) {
  const updateRemasterCount = yield apiCall();
  yield updateRemasterCount.post(makeUrl("/admin/remaster-override"), {
    localUserId,
    count,
  });
  yield getAllUsers();
}
