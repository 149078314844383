import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* getCategories() {
  yield put({ type: "SET_LOADING", key: "categories", loading: true });
  const getCategoriesCall = yield apiCall();
  const result = yield getCategoriesCall.get(makeUrl("/category"));
  yield put({ type: "SAVE_CATEGORIES", categories: result.data });
  yield put({ type: "SET_LOADING", key: "categories", loading: false });
}
