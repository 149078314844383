import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* buyAlacarteAIMinutes({ callback }) {
  yield put({
    type: "SET_LOADING",
    key: "buy-alacarte-ai-minutes",
    loading: true,
  });
  const postAIMinutesCall = yield apiCall();
  yield postAIMinutesCall.post(makeUrl("/subscription/alacarte-ai-minutes"), {});
  yield put({
    type: "SET_LOADING",
    key: "buy-alacarte-ai-minutes",
    loading: false,
  });
  yield put({
    type: "SET_SUCCESS",
    success: "You have 200 additional AI minutes available.",
  });
  callback();
}
