import { forwardRef, ComponentProps } from "react";

const FlagIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.375 16.17 5 4.98H11.25v-3.113H8.125V6.834h8.75v4.357h7.5l-5 4.98Z"
      fill="#FB462A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.625 5.59h2.5v21.784h-2.5V5.588ZM16.25 18.036l-5 3.112v-3.112h5Z"
      fill="#2E20B1"
    />
  </svg>
));

export default FlagIcon;
