import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import { getSubscription } from "./SubscriptionSaga";

export function* buyAlacarteRemasters({ callback }) {
  yield put({
    type: "SET_LOADING",
    key: "buy-alacarte-remasters",
    loading: true,
  });
  const postRemastersCall = yield apiCall();
  yield postRemastersCall.post(makeUrl("/subscription/alacarte-remasters"), {});
  yield put({
    type: "SET_LOADING",
    key: "buy-alacarte-remasters",
    loading: false,
  });
  yield put({
    type: "SET_SUCCESS",
    success: "You have 5 additional remasters available.",
  });
  yield getSubscription();
  callback();
}
