import AuthAdapterInterface from "./AuthAdapterInterface";

let adapterInstance: AuthAdapterInterface;

export const use = async (adapter: AuthAdapterInterface) => {
  adapterInstance = adapter;
  if (adapterInstance.onInit) adapterInstance.onInit();
};

export const isAuthenticated = async () => {
  return await adapterInstance.isAuthenticated();
};

export const getAccessToken = async () => {
  return await adapterInstance.getAccessToken();
};

export const getIdToken = async () => {
  return await adapterInstance.getIdToken();
};

export const loginWithRedirect = async (returnTo?: string) => {
  return await adapterInstance.loginWithRedirect(returnTo);
};

export const signupWithRedirect = async (returnTo?: string) => {
  return await adapterInstance.signupWithRedirect(returnTo);
};

export const handleRedirectCallback = async (onRedirect?: (returnTo: string) => void) => {
  return await adapterInstance.handleRedirectCallback(onRedirect);
};

export const getUser = async () => {
  return await adapterInstance.getUser();
};

export const logout = async () => {
  return await adapterInstance.logout();
};
