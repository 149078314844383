// @ts-check
import axios from "axios";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "@helloaudio/auth";
import { makeUrl } from "./apiCall";

/**
 * Send event data to the tracking API.
 * @param {string} event Event name
 * @param {{[key: string]: string | number}} [data = {}] Event data to send
 * @returns {Promise<void>} Promise that resolves when the request is complete
 */
export const sendTrackingData = async (event, data = {}) => {
  const endpoint = makeUrl("/track");
  const params = { event, ...data };

  try {
    /** @type {import('axios').AxiosRequestHeaders} */
    const headers = {};
    const isAuthenticated = await auth.isAuthenticated();
    if (isAuthenticated) {
      const token = await auth.getIdToken();
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await axios.get(endpoint, { headers, params });
    return response.data;
  } catch (error) {
    console.error("Error sending tracking data:", error);
  }
};

/**
 * Send pageview tracking data to the tracking API.
 */
export function usePageView() {
  const location = useLocation();

  useEffect(() => {
    const trackPageView = () => {
      sendTrackingData("pageview", {
        external: 0,
        pathname: location.pathname,
        search: location.search,
        timestamp: new Date().toISOString(),
      });
    };

    trackPageView();
  }, [location]);
}
