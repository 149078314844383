import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import querystring from "querystring";

export function* getAllStatistics({ beginDateUnix, endDateUnix }) {
  yield put({ type: "SET_LOADING", key: "get-all-statistics", loading: true });
  const getAllUsersCall = yield apiCall();
  const result = yield getAllUsersCall.get(
    makeUrl("/admin/statistics?") + querystring.encode({ beginDateUnix, endDateUnix }),
  );
  yield put({ type: "SAVE_ALL_STATISTICS", allStatistics: result.data });
  yield put({ type: "SET_LOADING", key: "get-all-statistics", loading: false });
}

export function* getStatistics({ feedId, email, episodeId, beginDateUnix, endDateUnix }) {
  yield put({ type: "SET_LOADING", key: "get-statistics", loading: true });
  const getStatistics = yield apiCall();
  const result = yield getStatistics.get(
    makeUrl(
      "/statistics?" +
        querystring.encode({
          feedId,
          email,
          episodeId,
          beginDateUnix,
          endDateUnix,
        }),
    ),
  );
  yield put({ type: "SAVE_STATISTICS", statistics: result.data });
  yield put({ type: "SET_LOADING", key: "get-statistics", loading: false });
}
