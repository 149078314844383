import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* getMyIntegrations() {
  yield put({ type: "SET_LOADING", key: "integrations", loading: true });
  const postIntegrationsCall = yield apiCall();
  const result = yield postIntegrationsCall.get(makeUrl("/my-integrations"));
  yield put({ type: "SAVE_INTEGRATIONS", integrations: result.data });
  if (window.location.pathname.match(/start-thinkific/) && result.data.thinkific.installLink) {
    window.location.href = result.data.thinkific.installLink;
  }
  yield put({ type: "SET_LOADING", key: "integrations", loading: false });
}

export function* regenerateApiKey() {
  yield put({ type: "SET_LOADING", key: "regenerate-api-key", loading: true });
  const postApiKeyCall = yield apiCall();
  const result = yield postApiKeyCall.post(makeUrl("/api-key"), {});
  yield put({
    type: "SAVE_SINGLE_INTEGRATION",
    integration: { api_key: result.data },
  });
  yield put({ type: "SET_LOADING", key: "regenerate-api-key", loading: false });
}
