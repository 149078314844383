import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* sendTestEmail({ feedId, content, useReminderTemplate }) {
  const key = "send-test-email";
  yield put({ type: "SET_LOADING", key, loading: true });
  const sendTestEmailCall = yield apiCall();
  yield sendTestEmailCall.post(makeUrl("/send-test-email"), {
    feedId,
    content,
    useReminderTemplate,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({
    type: "SET_SUCCESS",
    success:
      "Check your email to see what your listeners will receive. (It uses the Universal Link.)",
  });
}
