import { Fragment, ReactElement } from "react";
import clsx from "clsx";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { Label } from "@helloaudio/ui";

interface CardRadioGroupProps<T> {
  label?: string;
  className?: string;
  columns?: 1 | 2 | 3;
  value: T;
  onChange: (value: T) => void;
  options: {
    key?: string;
    value: T;
    disabled?: boolean;
    render:
      | {
          label?: string;
          description?: string;
          info?: string;
        }
      | ((props: { checked: boolean; active: boolean; disabled: boolean }) => ReactElement);
  }[];
}

function CardRadioGroup<T>(props: CardRadioGroupProps<T>) {
  const { className, value, onChange, label, columns, options } = props;

  return (
    <RadioGroup className={className} value={value} onChange={onChange}>
      {label && (
        <RadioGroup.Label as={Label} className="tw-mb-2">
          {label}
        </RadioGroup.Label>
      )}

      <fieldset
        className={clsx(
          "tw-grid tw-grid-cols-1 tw-gap-y-4 md:tw-gap-x-4",
          columns === 1 && "md:tw-grid-cols-1",
          columns === 2 && "md:tw-grid-cols-2",
          columns === 3 && "md:tw-grid-cols-3",
        )}
      >
        {options.map((option, index) => (
          <RadioGroup.Option
            key={option.key !== undefined ? option.key : index}
            value={option.value}
            disabled={option.disabled}
            className={({ checked, active, disabled }) =>
              clsx(
                "tw-border tw-border-solid tw-relative tw-bg-white tw-rounded tw-p-4 tw-flex focus:tw-outline-none",
                checked && "tw-border-transparent",
                !checked && "tw-border-gray-300",
                active && "tw-ring-2 tw-ring-green-300",
                !disabled && "tw-cursor-pointer",
                disabled && "tw-bg-gray-100",
              )
            }
          >
            {({ checked, active, disabled }) => (
              <Fragment>
                <div className="tw-flex-1 tw-flex">
                  <div className="tw-flex tw-flex-col">
                    {typeof option.render === "object" ? (
                      <Fragment>
                        {option.render.label && (
                          <RadioGroup.Label
                            as="span"
                            className="tw-block tw-text-sm tw-font-medium tw-text-gray-900"
                          >
                            {option.render.label}
                          </RadioGroup.Label>
                        )}
                        {option.render.description && (
                          <RadioGroup.Description
                            as="span"
                            className={clsx(
                              "tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500",
                              option.render.info && "tw-mb-6",
                            )}
                          >
                            {option.render.description}
                          </RadioGroup.Description>
                        )}
                        {option.render.info && (
                          <RadioGroup.Description
                            as="span"
                            className="tw-mt-auto tw-text-sm tw-font-medium tw-italic tw-text-gray-900"
                          >
                            {option.render.info}
                          </RadioGroup.Description>
                        )}
                      </Fragment>
                    ) : (
                      option.render({ checked, active, disabled })
                    )}
                  </div>
                </div>
                <CheckCircleIcon
                  className={clsx("tw-h-5 tw-w-5 tw-text-green-500", !checked && "tw-invisible")}
                  aria-hidden="true"
                />
                <div
                  className={clsx(
                    "tw-border-solid tw-absolute -tw-inset-px tw-rounded tw-pointer-events-none",
                    active && "tw-border",
                    !active && "tw-border",
                    checked && "tw-border-green-500",
                    !checked && "tw-border-transparent",
                  )}
                  aria-hidden="true"
                />
              </Fragment>
            )}
          </RadioGroup.Option>
        ))}
      </fieldset>
    </RadioGroup>
  );
}

CardRadioGroup.defaultProps = {
  columns: 1,
};

export default CardRadioGroup;
