import { ComponentProps, forwardRef } from "react";

const PlusCircleIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9a.857.857 0 0 0-.857.857v4.286H9.857a.857.857 0 1 0 0 1.714h4.286v4.286a.857.857 0 0 0 1.714 0v-4.286h4.286a.857.857 0 0 0 0-1.714h-4.286V9.857A.857.857 0 0 0 15 9Z"
      fill="currentColor"
    />
    <circle cx={15} cy={15} r={11} stroke="currentColor" strokeWidth={2} />
  </svg>
));

export default PlusCircleIcon;
