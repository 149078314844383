import { auth } from "@helloaudio/auth";

import GuardFunction from "../GuardFunction";

/**
 * Requires users to be authenticated.
 * Unauthenticated users will be redirected to login page.
 *
 * @returns boolean
 */
const loginGuard: GuardFunction = async () => {
  const isAuthenticated = await auth.isAuthenticated();
  if (!isAuthenticated) {
    await auth.loginWithRedirect();
    return false;
  }

  return true;
};

export default loginGuard;
