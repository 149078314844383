import { auth } from "@helloaudio/auth";

/**
 * Wrapper around fetch, which automatically sets content-type and authorization headers
 *
 * @param path
 * @param data
 * @param options
 * @returns
 */
const call = async (path: string, data?: any, options?: RequestInit) => {
  const headers = new Headers(options?.headers);

  // Add authorization header if we have token
  if (!headers.has("Authorization")) {
    const isAuthenticated = await auth.isAuthenticated();
    if (isAuthenticated) {
      const token = await auth.getIdToken();
      if (token) headers.set("Authorization", `Bearer ${token}`);
    }
  }

  // Add content-type header depending on data type
  if (!headers.has("Content-Type")) {
    if (data instanceof FormData) headers.set("Content-Type", "multipart/form-data");
    else headers.set("Content-Type", "application/json");
  }

  // Format data depending on content-type header
  if (headers.get("Content-Type") === "application/json") {
    data = JSON.stringify(data);
  }

  if (path.startsWith("/")) path = path.substring(1);
  return await fetch(`${process.env.API_URL}/${path}`, {
    ...options,
    headers,
    body: data,
  });
};

/**
 * Shorthand functions for call
 *
 * @param path
 * @param data
 * @param options
 * @returns
 */
const get = async (path: string, data?: any, options?: RequestInit) =>
  await call(path, data, { ...options, method: "GET" });
const post = async (path: string, data?: any, options?: RequestInit) =>
  await call(path, data, { ...options, method: "POST" });
const put = async (path: string, data?: any, options?: RequestInit) =>
  await call(path, data, { ...options, method: "PUT" });
const del = async (path: string, data?: any, options?: RequestInit) =>
  await call(path, data, { ...options, method: "DELETE" });

/**
 * Client
 */
const apiClient = {
  call,
  get,
  post,
  put,
  delete: del,
};

export default apiClient;
