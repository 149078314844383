import { forwardRef, SelectHTMLAttributes, useId } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";

import { Label } from "@helloaudio/ui";

interface NativeSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options?: {
    key: string;
    label: string;
    value: string;
  }[];
}
const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(
  ({ className, label, options, placeholder, ...htmlSelectAttributes }, ref) => {
    const id = useId();

    return (
      <div className={className}>
        {label && (
          <Label htmlFor={`select${id}`} className="tw-mb-2">
            {label}
          </Label>
        )}

        <div className="tw-relative">
          <select
            {...htmlSelectAttributes}
            ref={ref}
            id={`select${id}`}
            className="tw-peer tw-cursor-pointer tw-transition-colors tw-duration-100 tw-form-select tw-block tw-w-full tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-0 focus:tw-border-indigo-800 focus:tw-bg-indigo-10 sm:tw-text-sm tw-rounded"
          >
            {placeholder && (
              <option value="" disabled hidden>
                {placeholder}
              </option>
            )}
            {options?.map((option) => (
              <option key={option.key} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <ChevronUpDownIcon
            className="tw-transition-colors tw-duration-100 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-right-2 tw-flex tw-items-center tw-pointer-events-none tw-h-5 tw-w-5 tw-text-gray-400 tw-bg-white peer-focus:tw-bg-indigo-10"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  },
);

NativeSelect.displayName = "NativeSelect";

export default NativeSelect;
