import Axios from "axios";

import { auth } from "@helloaudio/auth";

export const makeUrl = (endpoint) => {
  return process.env.API_URL + endpoint;
};

export default async (configuration) => {
  const token = await auth.getIdToken();
  try {
    return Axios.create({
      ...configuration,
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    return Axios.create({ ...configuration });
  }
};
