const generateInitials = (name: string) => {
  const split = name
    .split(" ")
    .map((part) => part.trim())
    .filter((part) => !!part)
    .map((part) => part.substring(0, 1));

  const first = split.shift() || "";
  const last = split.pop() || "";

  return first + last;
};

export default generateInitials;
