import { cloneElement, FunctionComponent, ReactNode, useMemo, useState } from "react";
import {
  Placement,
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
} from "@floating-ui/react-dom-interactions";
import { mergeRefs } from "react-merge-refs";

interface TooltipProps {
  label: ReactNode;
  placement?: Placement;
  children: JSX.Element;
  bgColor?: string;
  className?: string;
  helpTextColor?: string;
}

const Tooltip: FunctionComponent<TooltipProps> = ({
  className,
  helpTextColor,
  children,
  label,
  bgColor,
  placement = "top",
}) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift({ padding: 2 })],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  // Preserve the consumer's ref
  const ref = useMemo(() => mergeRefs([reference, (children as any).ref]), [reference, children]);

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
      {open && (
        <div
          ref={floating}
          className={`${className} tw-max-w-xs tw-text-center tw-bg-black tw-text-sm tw-font-normal tw-font-sans tw-shadow tw-text-white tw-rounded tw-px-2 tw-py-1 tw-text-left`}
          style={{
            background: bgColor,
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...getFloatingProps()}
        >
          {label}
          <div
            className="tw-w-1.5 tw-h-1.5 tw-absolute tw-left-[calc(50%-3px)] tw-bg-indigo-800 tw-rotate-45"
            style={{ backgroundColor: helpTextColor }}
          />
        </div>
      )}
    </>
  );
};

export default Tooltip;
