import { forwardRef, ComponentProps } from "react";

const ShieldCheckIcon = forwardRef<SVGSVGElement, ComponentProps<"svg">>((props, svgRef) => (
  <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.323 25.625c-9.956-3.858-9.053-17-9.053-17 6.027 1.111 9.053-4.25 9.053-4.25s3.019 5.361 9.032 4.25c0 0 .902 13.142-9.032 17Z"
      fill="#2E20B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.625 15.625 3.125 3.125L20 12.5l-1.25-1.25-5 5-1.875-1.875-1.25 1.25Z"
      fill="#fff"
    />
  </svg>
));

export default ShieldCheckIcon;
