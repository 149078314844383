import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { ChevronDownIcon, Bars4Icon, XMarkIcon, FingerPrintIcon } from "@heroicons/react/24/solid";

import {
  FlagIcon,
  HeadphonesIcon,
  HorizontalAdjustmentsIcon,
  LightningBoltIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SpeakerphoneIcon,
  BillingIcon,
} from "@helloaudio/icons/twotone";
import { Avatar } from "@helloaudio/ui";
import { CogIcon, LogoutIcon, UserIcon } from "@helloaudio/icons/twotone";
import { auth } from "@helloaudio/auth";
import { useLocalUserQuery } from "@helloaudio/api";
import { NavLink } from "@helloaudio/router";
import { useOnClickOutside, useToggle } from "@helloaudio/hooks";

import logoFullDark from "@helloaudio/assets/logo-full-dark.svg";

const NAVIGATIONS = [
  [
    {
      icon: LightningBoltIcon,
      label: "My Feeds",
      href: "/my-feeds",
    },
    {
      icon: HeadphonesIcon,
      label: "My Listeners",
      href: "/listeners",
    },
    {
      icon: HorizontalAdjustmentsIcon,
      label: "My Actions",
      href: "/actions",
    },
  ],
  [
    {
      icon: FlagIcon,
      label: "Affiliates",
      href: "/affiliate",
    },
    {
      icon: RefreshIcon,
      label: "Integrations",
      href: "/integrations",
    },
    {
      icon: SpeakerphoneIcon,
      label: "Audio Inbox",
      href: "/audio-inbox",
    },
    {
      icon: ShieldCheckIcon,
      label: "Help",
      href: "https://help.helloaudio.fm",
      rel: "noopener noreferrer",
      target: "_blank",
    },
  ],
];

const Topbar: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { data: localUser } = useLocalUserQuery();
  const userNavigation = useMemo(
    () => [
      {
        icon: UserIcon,
        label: "My Account",
        href: "/account",
      },
      {
        icon: BillingIcon,
        label: "Subscription",
        href: "/subscription",
      },
      {
        icon: FingerPrintIcon,
        label: "End Spoofing",
        as: "a",
        tabIndex: 0,
        hidden: !localUser!.spoofingUser,
        onClick: () => {
          dispatch({ type: "SPOOF" });
        },
      },
      {
        icon: CogIcon,
        label: "Admin Tools",
        href: "/admin-tools",
        hidden: !localUser!.superuser,
      },
      {
        icon: LogoutIcon,
        label: "Logout",
        as: "a",
        tabIndex: 0,
        onClick: () => auth.logout(),
      },
    ],
    [localUser],
  );

  const [topbarOpened, _, setTopbarOpened, setTopbarClosed] = useToggle();
  const [usermenuOpened, toggleUsermenu, __, setUsermenuClosed] = useToggle();

  const closeTopbar = useCallback(() => {
    setTopbarClosed();
    setUsermenuClosed();
  }, [setTopbarClosed, setUsermenuClosed]);

  const toggleTopbar = () => {
    if (topbarOpened) closeTopbar();
    else setTopbarOpened();
  };

  /**
   * Close when orientation changes
   */
  useEffect(() => {
    if (screen.orientation) {
      screen.orientation.addEventListener("change", closeTopbar);
    }

    return () => {
      if (screen.orientation) {
        screen.orientation.removeEventListener("change", closeTopbar);
      }
    };
  }, [closeTopbar]);

  /**
   * Close when clicked outside
   */
  const ref = useRef<HTMLElement>(null);
  useOnClickOutside(ref, closeTopbar);

  return (
    <header
      id="ha_topbar"
      ref={ref}
      className={clsx(
        "md:tw-hidden tw-fixed tw-z-topbar tw-top-0 tw-left-0 tw-right-0 tw-bg-white",
        !topbarOpened && "tw-shadow-sm",
        topbarOpened && "tw-shadow-lg tw-rounded-b-3xl",
      )}
    >
      <div className="tw-h-16 tw-px-4 tw-flex tw-items-center">
        {/* Logo */}
        <NavLink className="tw-mr-auto" href="/" onClick={closeTopbar}>
          <img className="tw-w-11" src={logoFullDark} />
        </NavLink>

        {/* Notifications button */}
        {/* <button className="tw-mr-3 tw-p-0 tw-rounded tw-border-none tw-bg-transparent focus:tw-outline-none focus-visible:tw-bg-slate-200 focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500">
          <BellIcon className="tw-w-8 tw-h-8" />
        </button> */}

        {/* Hamburger button */}
        <button
          className="tw-p-0 tw-rounded tw-border-none tw-bg-transparent focus:tw-outline-none focus-visible:tw-bg-slate-200 focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500"
          onClick={toggleTopbar}
        >
          {topbarOpened ? (
            <XMarkIcon className="tw-w-8 tw-h-8" />
          ) : (
            <Bars4Icon className="tw-w-8 tw-h-8" />
          )}
        </button>
      </div>

      {topbarOpened && (
        <div className="tw-pb-2">
          {/* Usermenu */}
          <div className="tw-w-full">
            <button
              className="tw-flex tw-w-full tw-h-12 tw-items-center tw-p-0 tw-px-4 tw-border-none tw-bg-transparent focus:tw-outline-none focus-visible:tw-bg-slate-200 focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500"
              onClick={toggleUsermenu}
            >
              <Avatar
                className="tw-mr-4"
                name={localUser!.last_known_name || ""}
                imageUrl={localUser!.last_known_profile_photo || ""}
                imageAltText={`${localUser!.last_known_name}'s profile picture`}
              />
              <span className="tw-text-black tw-font-semibold tw-text-sm">
                {localUser?.last_known_name}
              </span>
              <ChevronDownIcon className=" tw-ml-3 tw-shrink-0 tw-w-5 tw-h-5 tw-text-indigo-800" />
            </button>

            {usermenuOpened && (
              <nav>
                <ul className="tw-list-none tw-flex tw-flex-col tw-p-0 tw-m-0">
                  {userNavigation.map(
                    ({ icon: Icon, label, hidden, onClick, as, ...htmlAnchorAttributes }, index) =>
                      !hidden && (
                        <li key={index}>
                          <NavLink
                            {...htmlAnchorAttributes}
                            as={as as "a"}
                            className={({ isActive }) =>
                              clsx(
                                "tw-pl-14 tw-pr-4 tw-h-12 hover:tw-no-underline hover:tw-text-black tw-flex tw-items-center tw-text-sm tw-text-black tw-font-semibold tw-gap-4 focus:tw-outline-none focus-visible:tw-bg-slate-200 focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500",
                                isActive && "tw-bg-slate-100",
                              )
                            }
                            onClick={() => {
                              if (onClick) onClick();
                              closeTopbar();
                            }}
                          >
                            <Icon className="tw-w-8 tw-h-8 tw-shrink-0" />
                            <span
                              className="tw-whitespace-nowrap"
                              style={{ marginLeft: label === "Billing" ? "-3px" : "" }}
                            >
                              {label}
                            </span>
                          </NavLink>
                        </li>
                      ),
                  )}
                </ul>
              </nav>
            )}
          </div>

          {/* Navigations */}
          {NAVIGATIONS.map((navigation, index) => (
            <nav key={index} className={index !== 0 ? "tw-mt-8" : undefined}>
              <ul className="tw-list-none tw-flex tw-flex-col tw-p-0 tw-m-0">
                {navigation.map(({ icon: Icon, label, ...htmlAnchorAttributes }, index) => (
                  <li key={index}>
                    <NavLink
                      {...htmlAnchorAttributes}
                      className={({ isActive }) =>
                        clsx(
                          "tw-px-4 tw-h-12 hover:tw-no-underline hover:tw-text-black tw-flex tw-items-center tw-text-sm tw-text-black tw-font-semibold tw-gap-4 focus:tw-outline-none focus-visible:tw-bg-slate-200 focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-indigo-500",
                          isActive && "tw-bg-slate-100",
                        )
                      }
                      onClick={closeTopbar}
                    >
                      <Icon className="tw-w-8 tw-h-8 tw-shrink-0" />
                      <span className="tw-whitespace-nowrap">{label}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
          ))}
        </div>
      )}
    </header>
  );
};

export default Topbar;
