import { FunctionComponent } from "react";
import clsx from "clsx";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

import { NavLink } from "@helloaudio/router";

import {
  FlagIcon,
  HeadphonesIcon,
  HorizontalAdjustmentsIcon,
  LightningBoltIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SpeakerphoneIcon,
} from "@helloaudio/icons/twotone";

import logoFullDark from "@helloaudio/assets/logo-full-dark.svg";

const NAVIGATIONS = [
  [
    {
      icon: LightningBoltIcon,
      label: "My Feeds",
      href: "/my-feeds",
    },
    {
      icon: HeadphonesIcon,
      label: "My Listeners",
      href: "/listeners",
    },
    {
      icon: HorizontalAdjustmentsIcon,
      label: "My Actions",
      href: "/actions",
    },
  ],
  [
    {
      icon: FlagIcon,
      label: "Affiliates",
      href: "/affiliate",
    },
    {
      icon: RefreshIcon,
      label: "Integrations",
      href: "/integrations",
    },
    {
      icon: SpeakerphoneIcon,
      label: "Audio Inbox",
      href: "/audio-inbox",
    },
    {
      icon: ShieldCheckIcon,
      label: "Help",
      href: "https://help.helloaudio.fm",
      rel: "noopener noreferrer",
      target: "_blank",
    },
  ],
];

const Sidebar: FunctionComponent = () => {
  return (
    <aside
      id="ha_sidebar"
      className="tw-hidden md:tw-block tw-overflow-hidden tw-overflow-y-auto tw-w-16 tw-h-screen tw-fixed tw-top-0 tw-left-0 tw-py-4 tw-shadow-sm hover:tw-shadow-lg tw-bg-white hover:tw-w-56 hover:tw-rounded-r-3xl tw-transition-all tw-duration-200 tw-z-sidebar tw-group"
    >
      <NavLink className="tw-block tw-w-11 tw-mx-auto" href="/">
        <img className="tw-w-11" src={logoFullDark} />
      </NavLink>

      {NAVIGATIONS.map((navigation, index) => (
        <nav key={index} className="tw-my-14">
          <ul className="tw-list-none tw-flex tw-flex-col tw-gap-4 tw-p-0 tw-m-0">
            {navigation.map(({ icon: Icon, label, ...htmlAnchorAttributes }, index) => (
              <NavLink
                key={index}
                {...htmlAnchorAttributes}
                className={({ isActive }) =>
                  clsx(
                    "hover:tw-no-underline hover:tw-text-black tw-flex tw-items-center tw-text-sm tw-text-black tw-font-semibold tw-h-10 tw-px-4 tw-gap-4 hover:tw-bg-slate-100",
                    isActive && "tw-bg-slate-100",
                  )
                }
              >
                <Icon className="tw-w-8 tw-h-8 tw-shrink-0" />
                <span className="tw-scale-0 tw-opacity-0 tw-whitespace-nowrap group-hover:tw-scale-100 group-hover:tw-opacity-100 tw-transition-all tw-duration-200 tw-origin-left">
                  {label}
                </span>
                {htmlAnchorAttributes.target === "_blank" && (
                  <ArrowTopRightOnSquareIcon className="tw-ml-auto group-hover:tw-text-slate-400 tw-w-4 tw-h-4 tw-shrink-0 tw-scale-0 tw-opacity-0 group-hover:tw-scale-100 group-hover:tw-opacity-100 tw-transition-all tw-duration-200 tw-origin-left" />
                )}
              </NavLink>
            ))}
          </ul>
        </nav>
      ))}
    </aside>
  );
};

export default Sidebar;
