import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import { history } from "@helloaudio/router";
import { getFeedEpisodes } from "./EpisodeSaga";
import querystring from "querystring";

export function* updateFeed({
  feedId,
  title,
  feedType,
  description,
  hostName,
  link,
  imageId,
  isPublic,
  language,
  category1,
  category2,
  keywords,
  explicit,
  chartablePrefix,
  redirect,
  expiresAt,
  expiresAfter,
  primaryColorCode,
  limitFeedsToAfterStartDate,
  welcomeEpisode,
  universalFooter,
  internalTitle,
  tags,
  suppressAffiliateLinks,
  emailReleaseText,
  optimizedMode,
  reminderRepeat,
  reminderInterval,
  reminderEmailsActive,
  useSeasons,
  subscribePageHeader,
  usePodtracPrefix,
  callback,
}) {
  yield put({ type: "SET_LOADING", key: "save-feed", loading: true });
  const postFeedCall = yield apiCall();
  const result = yield postFeedCall.post(makeUrl("/feed"), {
    feedId,
    title,
    type: feedType,
    description,
    hostName,
    link,
    imageId,
    isPublic,
    language,
    category1,
    category2,
    keywords,
    explicit,
    chartablePrefix,
    redirect,
    expiresAt,
    expiresAfter,
    primaryColorCode,
    limitFeedsToAfterStartDate,
    welcomeEpisode,
    universalFooter,
    internalTitle,
    tags,
    suppressAffiliateLinks,
    emailReleaseText,
    optimizedMode,
    reminderRepeat,
    reminderInterval,
    reminderEmailsActive,
    useSeasons,
    subscribePageHeader,
    usePodtracPrefix,
  });
  yield put({ type: "SET_LOADING", key: "save-feed", loading: false });
  yield put({ type: "SET_SAVED", key: "save-feed" });
  yield put({ type: "SET_SUCCESS", success: "Your feed has been saved" });
  yield history.push(`/feed/${result.data.newFeedId}`);
  if (callback) {
    yield callback();
  }
}

export function* getFeed({ feedId, silent, callback }) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-feed", loading: true });
  }
  const getFeedCall = yield apiCall();
  const result = yield getFeedCall.get(makeUrl("/feed?") + querystring.encode({ feedId }));
  yield put({ type: "SAVE_FEED", feed: result.data.feed });
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-feed", loading: false });
  }
  if (callback) {
    yield callback();
  }
}

export function* getMyFeeds(silent) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-my-feeds", loading: true });
  }
  const getMyFeedsCall = yield apiCall();
  const result = yield getMyFeedsCall.get(makeUrl("/my-feeds"));
  yield put({ type: "SAVE_MY_FEEDS", feeds: result.data.feeds });
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-my-feeds", loading: false });
  }
}

export function* updateFeedStatus({ feedId, status, callback }) {
  const key = `feed-status-${feedId}`;
  yield put({ type: "SET_LOADING", key, loading: true });
  const patchFeedCall = yield apiCall();
  yield patchFeedCall.patch(makeUrl("/feed/status"), {
    feedId,
    status,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({ type: "SET_SAVED", key });
  yield getMyFeeds({ silent: true });
  yield getFeed({ feedId, silent: true });
  if (callback) {
    callback();
  }
}

export function* updateFeedGracePeriod({ feedId, gracePeriod }) {
  const key = "feed-grace-period";
  yield put({ type: "SET_LOADING", key, loading: true });
  const patchFeedCall = yield apiCall();
  yield patchFeedCall.patch(makeUrl("/feed/grace-period"), {
    feedId,
    gracePeriod,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
}

export function* updateFeedTracking({ feedId, useChartablePrefix, usePodtracPrefix, callback }) {
  const key = "update-feed-tracking";
  yield put({ type: "SET_LOADING", key, loading: true });
  const patchFeedCall = yield apiCall();
  yield patchFeedCall.patch(makeUrl("/feed/tracking"), {
    feedId,
    useChartablePrefix,
    usePodtracPrefix,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  if (typeof callback === "function") {
    yield callback();
  }
}

export function* deleteFeed({ feedId, callback }) {
  const patchFeedCall = yield apiCall();
  yield patchFeedCall.delete(makeUrl("/feed?") + querystring.encode({ feedId }));
  yield getMyFeeds({ silent: true });
  callback();
}

export function* duplicateFeed({ feedId, feedType, callback }) {
  const key = "duplicate-feed";
  yield put({ type: "SET_LOADING", key, loading: true });
  const duplicateFeedCall = yield apiCall();
  const result = yield duplicateFeedCall.post(makeUrl("/feed/duplicate"), {
    feedId,
    type: feedType,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({ type: "SET_SUCCESS", success: "Your feed has been duplicated" });
  yield callback();
  yield getFeed({ feedId: "52aa8cf5-b9e6-41f3-95b3-b333dfe39a95" || result.data.newFeedId });
  yield history.push(`/feed/${"52aa8cf5-b9e6-41f3-95b3-b333dfe39a95" || result.data.newFeedId}`);
}

export function* changeFeedType({ feedId, feedType, callback, silent }) {
  const key = "change-feed-type";
  if (!silent) {
    yield put({ type: "SET_LOADING", key, loading: true });
  }
  const changeFeedCall = yield apiCall();
  yield changeFeedCall.post(makeUrl("/feed/change-type"), {
    feedId,
    type: feedType,
  });
  if (!silent) {
    yield put({ type: "SET_LOADING", key, loading: false });
    yield put({ type: "SET_SUCCESS", success: "Your feed has been changed" });
    if (typeof callback === "function") {
      yield callback();
    }
  }
  yield getFeed({ feedId });
  yield getFeedEpisodes({ feedId });
}

export function* importFeed({ isPublic, feedUrl }) {
  const importFeed = yield apiCall();
  yield importFeed.post(makeUrl("/feed/import"), {
    isPublic,
    feedUrl,
  });
  // makes the feed import status reappear on the /my-feeds page if it's hidden.
  localStorage.removeItem("showImportStatus"); //eslint-disable-line
  yield history.push("/my-feeds");
}

export function* getImportFeedStatus() {
  const getImportFeedStatus = yield apiCall();
  const result = yield getImportFeedStatus.get(makeUrl("/feed/import"));
  yield put({
    type: "SAVE_IMPORT_FEED_STATUS",
    feedImport: result.data.imports,
  });
}

export function* getFeedRolls({ feedId, silent }) {
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-feed-rolls", loading: true });
  }
  const getFeedRollsCall = yield apiCall();
  const result = yield getFeedRollsCall.get(
    makeUrl("/feed-rolls?") + querystring.encode({ feedId }),
  );
  yield put({ type: "SAVE_FEED_ROLLS", feedRolls: result.data.feedRolls });
  yield put({
    type: "SAVE_FEED_SLOT_RULES",
    feedSlotRules: result.data.feedSlotRules,
  });
  if (!silent) {
    yield put({ type: "SET_LOADING", key: "get-feed-rolls", loading: false });
  }
}

export function* finishFeedRollUpload({ rollObject }) {
  yield getFeedRolls({ feedId: rollObject.feedId, silent: true });
}

export function* updateFeedSlotRule({ feedId, slot, enabled, startsAt, endsAt }) {
  yield put({ type: "SET_LOADING", key: `save-slot-${slot}`, loading: true });
  const postFeedSlotRuleCall = yield apiCall();
  yield postFeedSlotRuleCall.post(makeUrl("/feed-slot-rule"), {
    feedId,
    slot,
    enabled,
    startsAt,
    endsAt,
  });
  yield getFeedRolls({ feedId, silent: true });
  yield put({ type: "SET_LOADING", key: `save-slot-${slot}`, loading: false });
  yield put({
    type: "SET_SUCCESS",
    success: `Slot ${slot} has been saved. make sure you "Apply Dynamic Content to Episodes" to push this change to your listeners.`,
  });
}

export function* scheduleFeedRegeneration({ feedId, silent = false }) {
  if (!silent) {
    yield put({
      type: "SET_LOADING",
      key: "schedule-feed-regeneration",
      loading: true,
    });
  }
  const postRegenerationCall = yield apiCall();
  yield postRegenerationCall.post(makeUrl("/schedule-feed-regeneration"), {
    feedId,
  });
  if (!silent) {
    yield getFeedRolls({ feedId, silent: true });
    yield put({
      type: "SET_LOADING",
      key: "schedule-feed-regeneration",
      loading: false,
    });
    yield put({
      type: "SET_SUCCESS",
      success:
        "Dynamic content is being applied to the feed. This may take up to an hour to complete.",
    });
  }
}

export function* deleteFeedRoll({ feedId, slot, position, callback }) {
  const deleteAction = yield apiCall();
  yield deleteAction.delete(
    makeUrl("/feed-roll?") + querystring.encode({ feedId, slot, position }),
  );
  yield put({
    type: "SET_SUCCESS",
    success: "This feed roll has been deleted.",
  });
  yield getFeedRolls({ feedId, silent: true });
  callback();
}

export function* submitFeedToSpotify({ feedId, spotifyUri, callback }) {
  yield put({ type: "SET_LOADING", key: "upload-to-spotify", loading: true });
  const postSubmitToSpotify = yield apiCall();
  yield postSubmitToSpotify.post(makeUrl("/feed/spotify-push"), {
    feedId,
  });
  if (spotifyUri) {
    yield put({
      type: "SET_SUCCESS",
      success: "Your feed will be removed from Spotify in 15 minutes to an hour.",
    });
  } else {
    yield put({
      type: "SET_SUCCESS",
      success:
        "Refresh this page to get your Spotify link. Your content will appear in Spotify in 15 minutes to an hour.",
    });
  }
  yield put({ type: "SET_LOADING", key: "upload-to-spotify", loading: false });
  yield getFeed({ feedId });
  callback();
}

export function* exportFeedListenerCSV({ feedId }) {
  const exportCall = yield apiCall();

  exportCall.post(makeUrl("/listeners/export-csv"), {
    feedId,
  });
}
