import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import { history } from "@helloaudio/router";

export function* submitDescriptImport({ feedId, title, url }) {
  yield put({
    type: "SET_LOADING",
    key: "submit-descript-import",
    loading: true,
  });
  const postDescriptCall = yield apiCall();
  yield postDescriptCall.post(makeUrl("/descript-import"), {
    feedId,
    title,
    url,
  });
  yield put({
    type: "SET_LOADING",
    key: "submit-descript-import",
    loading: false,
  });
  yield history.push(`/feed/${feedId}`);
}
