import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";
import { getSubscription } from "./SubscriptionSaga";

export function* cancelPlan() {
  yield put({ type: "SET_LOADING", key: "cancel-plan", loading: true });
  const patchProfileCall = yield apiCall();
  yield patchProfileCall.delete(makeUrl("/subscription"), {});
  yield put({ type: "SET_LOADING", key: "cancel-plan", loading: false });
  yield put({ type: "SET_SAVED", key: "cancel-plan" });
  yield put({
    type: "SET_SUCCESS",
    success: "Your subscription has been canceled. We are so sorry to see you go.",
  });
  yield getSubscription();
}

export function* showChangePlan() {
  yield history.push("/account");
}

export function* loginConnectAccount() {
  const customerPortalSessionCall = yield apiCall();
  const result = yield customerPortalSessionCall.get(makeUrl("/stripe-connect/login"));
  window.location = result.data.url;
}
