import apiCall, { makeUrl } from "../../utils/apiCall";
import { put } from "redux-saga/effects";

export function* updateEmailContent({ feedId, content, callback }) {
  const key = "update-email-content";
  yield put({ type: "SET_LOADING", key, loading: true });
  const patchFeedCall = yield apiCall();
  yield patchFeedCall.patch(makeUrl("/feed/email-content"), {
    feedId,
    content,
  });
  yield put({ type: "SET_LOADING", key, loading: false });
  yield put({ type: "GET_FEED", feedId, silent: true });
  callback();
}
